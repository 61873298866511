<template>
  <nav class="navbar">
    <a href="/">
      <img src="../assets/logo/logo.png" alt="Logo" class="logo" />
    </a>

    <a href="tel:618107617" class="phone-nav">
      <img src="../assets/icons/phone.svg" />
    </a>

    <div class="hamburger-menu" @click="toggleNav">
      <div class="line line-1"></div>
      <div class="line line-2"></div>
      <div class="line line-3"></div>
    </div>
    <ul class="nav-list" :class="{ 'active': isNavOpen }">
      <li class="nav-item" v-for="item in yourNavItems" :key="item.id"
        @mouseover="item.subMenu ? showSubMenu(item.id) : null" @mouseleave="item.subMenu ? hideSubMenu() : null">

        <router-link :to="item.route" v-if="!item.subMenu" @click="closeNav">
          {{ item.title }}
        </router-link>

        <a href="#" v-else @click.prevent="showSubMenu(item.id)">
          {{ item.title }}
        </a>

        <!-- <ul class="sub-menu" v-if="item.subMenu" v-show="activeSubMenuIndex === item.id">
          <li v-for="subItem in item.subMenu" :key="subItem.route" class="nav-item sub-elem">
            <router-link :to="subItem.route" @click="closeNav">{{ subItem.name }}</router-link>
          </li>
        </ul> -->

        <ul class="sub-menu" v-if="item.subMenu" v-show="activeSubMenuIndex === item.id">
          <li v-for="subItem in item.subMenu" :key="subItem.route" class="nav-item sub-elem">

            <a v-if="subItem.type === 'external'" :href="subItem.route" target="_blank" rel="noopener noreferrer"
              @click="closeNav">{{ subItem.name }}</a>

            <router-link v-else :to="subItem.route" @click="closeNav">{{ subItem.name }}</router-link>
          </li>
        </ul>
      </li>
      <a href="tel:61 810 76 17">
        <div class="top-tele">

          <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
            <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt"
              d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z"
              transform="translate(0 0)" fill="#4d4d4d;" />
          </svg>
          61 810 76 17

        </div>
      </a>
    </ul>

    <!-- <div class="flag-container">
      <img src="../assets/flags/poland.svg" />
      <img src="../assets/flags/united-kingdom.svg" />
      <img src="../assets/flags/germany.svg" />
    </div> -->

  </nav>
</template>

<script>
export default {
  name: 'AppNavbar',
  data() {
    return {
      isNavOpen: false,
      activeSubMenuIndex: null,
      yourNavItems: [
        {
          id: 'jurpolGroup',
          title: 'Jurpol Group',
          subMenu: [
            { name: 'O nas', route: '/o-nas' },
            {
              name: 'Koleje (RGS)',
              route: 'https://rg-service.pl/',
              type: 'external'
            },
            { name: 'Kariera', route: '/dolacz-do-nas' }
          ]
        },
        {
          id: 'szyby',
          title: 'Szyby',
          subMenu: [
            { name: 'Oferta', route: '/oferta-szyby' }
          ]
        },
        {
          id: 'serwis',
          title: 'Serwis',
          subMenu: [
            { name: 'Serwis mobilny', route: '/serwis-mobilny' },
            { name: 'Naprawa', route: '/naprawa-szyb' },
            { name: 'Camper', route: '/serwis-camper' },
          ],
        },
        {
          id: 'uslugi',
          title: 'Usługi',
          subMenu: [
            { name: 'Kalibracja', route: '/kalibracja-szyb' },
            { name: 'Przyciemnianie', route: '/przyciemnianie-szyb' },
            { name: 'Akcesoria', route: '/akcesoria' },
            { name: 'Ekoplakietka', route: '/ekoplakietka' },
            { name: 'Inside Garage', route: '/inside-garage' },
          ],
        },
        {
          id: 'ubezpieczenia',
          title: 'Ubezpieczenia',
          route: '/ubezpieczenia',
        },
        {
          id: 'kontakt',
          title: 'Kontakt',
          route: '/kontakt',
        },
        {
          id: 'blog',
          title: 'Blog',
          route: '/blog',
        }
      ],
    };
  },
  methods: {
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    },
    closeNav() {
      this.isNavOpen = false;
    },
    showSubMenu(id) {
      this.activeSubMenuIndex = id;
    },
    hideSubMenu() {
      this.activeSubMenuIndex = null;
    },
  },
};
</script>

<style scoped>
@import "../styles/navbar.css";
</style>