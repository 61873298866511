<template>
  <div class="green-side">
    <div class="header-container flex-parent">
      <div class="txt-side">
        <h1>SERWIS CAMPER</h1>
        <p>
          Firma Jurpol, specjalizująca się w dziedzinie szyb samochodowych,
          oferuje również kompleksowe rozwiązania dotyczące szyb do kamperów (
          wszystkie marki pojazdów półintegra oraz integra ). Nasza oferta
          obejmuje zarówno sprzedaż szyb, jak i ich wymianę oraz naprawę,
          zapewniając usługi na najwyższym poziomie.
        </p>
      </div>
      <div class="top-side">
        <img src="../assets/icons/camper.png" />
      </div>
    </div>
    <section>
      <h2 class="smaller">Dlaczego warto wybrać naszą usługę szyb do kamperów?</h2>
      <div class="arrows-container">
        <lottie-player class="arrows" src="/arrow.json" background="transparent" speed="1"
          style="width: 86px; height: 86px" loop autoplay></lottie-player>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Wymiana Szyb w Kamperach</h2>
            <p>
              Nasza specjalistyczna ekipa przeprowadzi profesjonalną wymianę
              szyb w Twoim kamperze, zapewniając dokładność i niezawodność.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Naprawa Szyb w Kamperach</h2>
            <p>
              Jeśli Twoja szyba uległa uszkodzeniu, oferujemy również usługi naprawy
              odprysków i pęknięć, co pozwoli Ci zaoszczędzić czas i pieniądze.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Doradztwo Techniczne</h2>
            <p>
              Nasz wykwalifikowany zespół specjalistów służy fachowym doradztwem
              technicznym, pomagając Ci w rozwiązywaniu problemów związanych z
              szybami do kamperów.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Wyposażenie Zewnętrzne i Wewnętrzne</h2>
            <p>
              Oferujemy również usługi dotyczące wyposażenia zewnętrznego kamperów
              tj. montaż i serwis paneli solarnych, klimatyzacji, markizy, anteny
              satelitarnej i pozostałych rozwiązań, dostosowanych do potrzeb
              kamperów. Ponadto, zapewniamy usługi związane z wyposażeniem
              wewnętrznym tj. montaż i serwis przetwornic, kamer cofania, sprzętu
              audio i pozostałych rozwiązań, które sprawią, że Twój kamper będzie
              jeszcze bardziej komfortowy i funkcjonalny.
            </p>
          </div>
        </div>
      </div>
      <div class="txt-section">
        <p>
          Niezależnie od potrzeb Twojego pojazdu rekreacyjnego, możesz polegać
          na naszym doświadczeniu, profesjonalizmie i szerokim zakresie usług
          oraz doradztwa. Firma Jurpol to pewność skutecznej obsługi szyb do
          kamperów.
        </p>
        <h2 class="cta">
          Skorzystaj z naszej kompleksowej oferty i ciesz się komfortem oraz
          bezpieczeństwem podczas podróży rekreacyjnych.
        </h2>
      </div>
    </section>
  </div>
</template>
  
<script>
export default {
  name: 'SerwisCamper',
};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  