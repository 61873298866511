<template>
    <div v-if="visible" class="modal popup">
        <div class="modal-content">
            <span class="close" @click="closeModal">&times;</span>
            <div v-if="applicationSubmitted">
                <p>Aplikacja wysłana</p>
            </div>
            <div v-else>
                <h2 class="title">{{ jobTitle }}</h2>
                <p class="intro">
                    Jurpol Sp. z o.o. Sp.k. to lider w branży szyb samochodowych na rynku wielkopolskim. Od ponad 30 lat
                    świadczymy usługi w zakresie kompleksowej sprzedaży, montażu, wymiany i naprawy szyb samochodowych
                    do
                    każdego rodzaju pojazdów, od samochodów osobowych, ciężarowych, autobusów, maszyn budowlanych czy
                    rolniczych oraz pojazdów komunikacji miejskiej. Na bieżąco śledzimy zachodzące zmiany na rynku i
                    podnosimy nasze kwalifikacje tak, aby zapewnić bardzo dobry serwis oraz bezpieczne rozwiązania.
                </p>
                <p class="green-cta">
                    {{ cta }}
                </p>
                <h2>Twój zakres obowiązków:</h2>
                <ul>
                    <li v-for="(responsibility, index) in responsibilities" :key="index">{{ responsibility }}</li>
                </ul>
                <h2>Nasze wymagania:</h2>
                <ul>
                    <li v-for="(requirement, index) in requirements" :key="index">{{ requirement }}</li>
                </ul>
                <h2>To oferujemy:</h2>
                <ul>
                    <li v-for="(offerItem, index) in offer" :key="index">{{ offerItem }}</li>
                </ul>

                <input type="hidden" name="formType" value="jobApplication">

                <div class="flex-parent">
                    <input type="checkbox" id="rodo" v-model="rodoChecked">
                    <label for="rodo">Aplikując na wybrane ogłoszenie wyraża Pani/Pan zgodę na przetwarzanie danych
                        osobowych w aktulanym postępowaniu rekrutacyjnym zgodnie z ustawą o ochronie danych osobowych.
                        Administratorem danych osobowych jest Jurpol Sp. z o.o. sp.k z siedzibą w Komornikach przy ulicy
                        Wirowskiej 30. Więcej informacji dotyczących przetwarzania danych osobowych znajduje się w
                        <a href="/assets/downloads/POLITYKA-OCHRONY-DANYCH-OSOBOWYCH-I-COOKIE-JURPOL.pdf"
                            download="Polityka-Prywatnosci">polityce prywatności</a>.
                    </label>
                </div>
                <div v-if="showRodoValidationError" class="validation-error">
                    Zaakceptuj politykę RODO
                </div>
                <br>
                <div>
                    <label for="cv">Załącz CV</label>
                    <input type="file" id="cv" @change="handleFileUpload" />
                    <div v-if="showCvValidationError" class="validation-error">
                        Nie dodano CV
                    </div>
                </div>
                <br>
                <br>
                <button v-if="!isSubmitting" @click="applyForJob" type="button">Aplikuj</button>
                <div style="display: flex; align-items: center; margin: 0 auto">
                    <p v-if="isSubmitting" style="text-align: center;">Wysyłanie...</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'JobApplyModal',
    props: ['visible', 'jobTitle', 'cta', 'responsibilities', 'requirements', 'offer', 'department'],
    data() {
        return {
            file: null,
            rodoChecked: false,
            applicationSubmitted: false,
            showRodoValidationError: false,
            showCvValidationError: false,
            isSubmitting: false,
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            this.applicationSubmitted = false;
            this.resetValidationErrors();
        },
        handleFileUpload(event) {
            this.file = event.target.files[0];
            this.showCvValidationError = false;
        },
        async applyForJob() {
            this.resetValidationErrors();
            let isValid = true;
            if (!this.rodoChecked) {
                this.showRodoValidationError = true;
                isValid = false;
            }
            if (!this.file) {
                this.showCvValidationError = true;
                isValid = false;
            }
            if (isValid) {
                this.isSubmitting = true;
                const formData = new FormData();
                formData.append('formType', 'jobApplication');
                formData.append('cv', this.file);
                formData.append('jobTitle', this.jobTitle);
                formData.append('department', this.department);
                formData.append('rodoChecked', this.rodoChecked ? 'Tak' : 'Nie');

                try {
                    const response = await fetch('https://jurpol.pl/sendMail.php', {
                        method: 'POST',
                        body: formData,
                    });

                    if (response.ok) {
                        this.applicationSubmitted = true;
                    } else {
                        throw new Error('Form submission failed');
                    }
                } catch (error) {
                    console.error('Error:', error);
                } finally {
                    this.isSubmitting = false;
                }
            }
        },


        resetValidationErrors() {
            this.showRodoValidationError = false;
            this.showCvValidationError = false;
        },
    },
};
</script>
<style scoped>
@import "../styles/popup.css";
</style>
