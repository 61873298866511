<template>
  <div class="green-side">
    <div class="header-container flex-parent">
      <div class="txt-side">
        <h1>PRZYCIEMNIANIE SZYB</h1>
        <p>
          Firma Jurpol to zaufany partner w dziedzinie przyciemniania szyb
          samochodowych, oferujący usługi na najwyższym poziomie. Nasza firma
          specjalizuje się w precyzyjnym i profesjonalnym przyciemnianiu szyb,
          zapewniając klientom nie tylko elegancki wygląd pojazdu, ale również
          ochronę przed promieniowaniem UV, ciepłem oraz wzmagającą się
          prywatność.
        </p>
      </div>
      <div class="top-side">
        <img src="../assets/icons/przyciemnianie.png" />
      </div>
    </div>
    <section>
      <h2 class="smaller">
        Dlaczego warto skorzystać z naszej usługi przyciemniania szyb
        samochodowych?
      </h2>
      <div class="arrows-container">
        <lottie-player class="arrows" src="/arrow.json" background="transparent" speed="1"
          style="width: 86px; height: 86px" loop autoplay></lottie-player>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Redukcja Ciepła</h2>
            <p>
              Zaawansowane technologie przyciemniania szyb pozwalają na
              utrzymanie komfortowej temperatury w pojeździe.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Prywatność i Bezpieczeństwo</h2>
            <p>
              Przyciemnione szyby zwiększają prywatność oraz działają jako dodatkowa
              ochrona przed włamaniem.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Elegancki Wygląd</h2>
            <p>
              Nasze usługi przyciemniania szyb nadają pojazdowi nowoczesny i
              elegancki charakter.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Precyzja i Profesjonalizm</h2>
            <p>
              Nasz wykwalifikowany zespół specjalistów stosuje najnowsze
              technologie, zapewniając najwyższą jakość usługi.
            </p>
          </div>
        </div>
      </div>
      <h2 class="smaller">Korzyści przyciemniania szyb w Firmie Jurpol:</h2>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/privacy.png" />
        </div>
        <div>
          <h3>Zwiększona Prywatność</h3>
          <p>
            Ochroń swoją prywatność przed ciekawskimi spojrzeniami na parkingu
            czy w korku.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/secure.png" />
        </div>
        <div>
          <h3>Ochrona Wnętrza Pojazdu</h3>
          <p>
            Przyciemnione szyby zapobiegają blaknięciu i uszkodzeniom wnętrza
            pojazdu spowodowanym promieniowaniem UV.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/shines.png" />
        </div>
        <div>
          <h3>Redukcja Blasku</h3>
          <p>Zmniejsz oślepiający blask słońca podczas jazdy.</p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/car-wash.png" />
        </div>
        <div>
          <h3>Poprawa Estetyki</h3>
          <p>Nadaj swojemu pojazdowi wyjątkowego charakteru i elegancję.</p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/smile.png" />
        </div>
        <div>
          <h3>Bezpieczeństwo i Prywatność</h3>
          <p>Zyskaj poczucie bezpieczeństwa i prywatności podczas podróży.</p>
        </div>
      </div>
      <div class="txt-section">
        <p>
          Firma Jurpol to pewność profesjonalnego przyciemniania szyb
          samochodowych, z dbałością o detale i oczekiwania klientów.
        </p>
        <h2 class="cta">
          Skorzystaj z naszych usług i ciesz się ochroną, prywatnością i
          eleganckim wyglądem swojego pojazdu.
        </h2>
      </div>
    </section>
  </div>
</template>
<script>

export default {
  name: 'PrzyciemnianieSzyb',
};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  