<template>
    <div class="form-component">
        <div class="sticky-toggle" @click="toggleForm">
            UMÓW SIĘ NA SERWIS
            <img src="../assets/icons/form.svg">
        </div>

        <div v-if="isFormVisible && !submissionSuccess" class="sticky-form">

            <form @submit.prevent="submitForm">

                <input type="hidden" name="formType" value="serviceAppointment">

                <h2>ZGŁOSZENIE USŁUGI</h2>

                <div class="form-flex-parent">
                    <button @click="toggleForm" class="close-btn">zamknij</button>
                    <div class="form-left">

                        <div class="choose">
                            <label for="firstName">Imię</label>
                            <input id="firstName" v-model="formData.firstName" type="text" required>
                        </div>

                        <div class="choose">
                            <label for="lastName">Nazwisko</label>
                            <input id="lastName" v-model="formData.lastName" type="text" required>
                        </div>

                        <div class="choose">
                            <label for="email">Adres e-mail</label>
                            <input id="email" v-model="formData.email" type="email" required>
                        </div>

                        <div class="choose">
                            <label for="phone">Numer telefonu</label>
                            <input id="phone" v-model="formData.phone" type="tel" required>
                        </div>

                        <div class="choose">
                            <label for="vin">Numer VIN pojazdu</label>
                            <input id="vin" v-model="formData.vin" type="text" required>
                        </div>
                    </div>

                    <div class="form-right">

                        <div class="choose">
                            <label for="serviceGoal">Cel zgłoszenia:</label>
                            <select id="serviceGoal" v-model="formData.serviceGoal" required>
                                <option value="">Wybierz</option>
                                <option value="wymiana_szyby">wymiana szyby</option>
                                <option value="naprawa_szyby">naprawa szyby (naprawa odprysku)</option>
                                <option value="przyciemnianie_szyb">przyciemnianie szyb</option>
                                <option value="kalibracja_systemu_ADAS">kalibracja systemu ADAS</option>
                            </select>
                        </div>

                        <div class="choose">
                            <label for="serviceType">Realizacja zgłoszenia:</label>
                            <select id="serviceType" v-model="formData.serviceType" required>
                                <option value="">Wybierz</option>
                                <option value="zgłoszenie_indywidualne">zgłoszenie indywidualne</option>
                                <option value="zgłoszenie_z_ubezpieczenia_Autoszyba/AC">zgłoszenie z ubezpieczenia
                                    Autoszyba/AC
                                </option>
                            </select>
                        </div>

                        <div class="choose">
                            <label for="serviceLocation">Gdzie chciałbyś wykonać usługę:</label>
                            <select id="serviceLocation" v-model="formData.serviceLocation" required>
                                <option value="">Wybierz</option>
                                <option value="Komorniki">Komorniki ul. Wirowska 30</option>
                                <option value="Poznań">Poznań ul. Lechicka 2</option>
                                <option value="Września">Września ul. Wrocławska 1a</option>
                            </select>
                        </div>

                        <div class="choose">
                            <label for="attachment">Załącznik - zdjęcie</label>
                            <input id="attachment" type="file" @change="handleFileUpload">
                        </div>

                        <div>
                            <input type="checkbox" id="consent" v-model="formData.consent" required>
                            <label for="consent">
                                <a href="/assets/downloads/POLITYKA-OCHRONY-DANYCH-OSOBOWYCH-I-COOKIE-JURPOL.pdf"
                                    download="Polityka-Prywatnosci">Zgoda RODO</a></label>
                        </div>
                    </div>
                </div>
                <button v-if="!isSubmitting" type="submit" class="submit">Wyślij zgłoszenie</button>

                <div style="display: flex; align-items: center; margin: 0 auto">
                    <p v-if="isSubmitting" style="text-align: center;">Wysyłanie...</p>
                </div>
            </form>
        </div>

        <div v-if="submissionSuccess" class="sticky-form">
            <h2>ZGŁOSZENIE USŁUGI</h2>
            <div class="form-flex-parent">
                <button @click="resetForm" class="close-btn">zamknij</button>
                <div style="width: 100%;">
                    <p style="text-align: center; font-size: 1.5rem">Formularz został wysłany. Dziękujemy za
                        zgłoszenie.<br>
                        Wkrótce skontaktujemy się z Tobą.</p>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            isFormVisible: false,
            submissionSuccess: false,
            isSubmitting: false,
            formData: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                vin: '',
                serviceGoal: '',
                serviceType: '',
                serviceLocation: '',
                consent: false,
                attachment: null,
            },
        };
    },
    methods: {
        handleFileUpload(event) {
            this.formData.attachment = event.target.files[0];
        },

        async submitForm() {
            if (this.isSubmitting) return;

            console.log('Form submission started');
            this.isSubmitting = true;

            const formData = new FormData();

            formData.append('formType', 'serviceAppointment');
            formData.append('firstName', this.formData.firstName);
            formData.append('lastName', this.formData.lastName);
            formData.append('email', this.formData.email);
            formData.append('phone', this.formData.phone);
            formData.append('vin', this.formData.vin);
            formData.append('serviceGoal', this.formData.serviceGoal);
            formData.append('serviceType', this.formData.serviceType);
            formData.append('serviceLocation', this.formData.serviceLocation);
            formData.append('consent', this.formData.consent ? 'Tak' : 'Nie');

            if (this.formData.attachment) {
                formData.append('attachment', this.formData.attachment);
            }

            try {
                console.log('Sending form data...');
                const response = await fetch('https://jurpol.pl/sendMail.php', {
                    method: 'POST',
                    body: formData,
                });

                const responseText = await response.text();
                console.log('Response received:', responseText);

                if (response.ok) {
                    this.submissionSuccess = true;
                    console.log('Form submission successful');
                    this.isFormVisible = false;
                    this.forceUpdate();
                } else {
                    this.submissionSuccess = false;
                    alert(`Nie udało się wysłać formularza: ${responseText}`);
                }
            } catch (error) {
                console.error('Error:', error);
            } finally {
                console.log('Form submission completed');
                this.isSubmitting = false; // Enable the button after the process completes
            }
        },

        toggleForm() {
            console.log('Toggling form visibility');
            if (this.isFormVisible && this.submissionSuccess) {
                this.resetForm();
            } else {
                this.isFormVisible = !this.isFormVisible;
            }
        },

        resetForm() {
            console.log('Resetting form');
            this.formData = {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                vin: '',
                serviceGoal: '',
                serviceType: '',
                serviceLocation: '',
                consent: false,
                attachment: null,
            };
            this.submissionSuccess = false;
            this.isFormVisible = false;
            this.isSubmitting = false;
        },

        forceUpdate() {
            this.$nextTick(() => {
                this.isFormVisible = true;
            });
        },
    },
};
</script>

<style scoped>
@import "../styles/sticky-form.css";
</style>
