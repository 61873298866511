<template>
  <div class="green-side">
    <div class="header-container flex-parent">
      <div class="txt-side">
        <h1>EKOPLAKIETKA</h1>
        <p>
          Ekoplakietka to specjalna naklejka lub identyfikator, który umożliwia
          dostęp do ekologicznych stref w miastach. Jej głównym celem jest
          ograniczenie emisji szkodliwych substancji do powietrza oraz poprawa
          jakości życia mieszkańców miejskich obszarów. Oto proste kroki, które
          musisz wykonać, aby zakupić ekoplakietkę w Firmie Jurpol:
        </p>
      </div>
      <div class="top-side">
        <img src="../assets/icons/ekoplakietka.png" />
      </div>
    </div>
    <section>
      <h2 class="smaller">Kroki do zakupu ekoplakietki:</h2>
      <div class="arrows-container">
        <lottie-player class="arrows" src="/arrow.json" background="transparent" speed="1"
          style="width: 86px; height: 86px" loop autoplay></lottie-player>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/1.png" />
        </div>
        <div class="how-use">
          <h2>Wybierz rodzaj ekoplakietki</h2>
          <p>
            Wspólnie dobierzemy odpowiednią ekoplakietkę dostosowaną do Twoich
            potrzeb, uwzględniając rodzaj pojazdu i miejsca, do których
            planujesz podróżować.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/2.png" />
        </div>
        <div class="how-use">
          <h2>Przygotuj wymagane dokumenty</h2>
          <p>
            W zależności od rodzaju ekoplakietki, mogą być wymagane pewne
            dokumenty, takie jak dowód rejestracyjny pojazdu. Upewnij się, że
            masz je przygotowane.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/3.png" />
        </div>
        <div class="how-use">
          <h2>Zamów ekoplakietkę</h2>
          <p>
            Po dokonaniu wyboru i potwierdzeniu dostępności, możesz złożyć
            zamówienie na ekoplakietkę.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/4.png" />
        </div>
        <div class="how-use">
          <h2>Odbierz ekoplakietkę</h2>
          <p>Odbierz ekoplakietkę osobiście w naszym punkcie obsługi.</p>
        </div>
      </div>
      <div class="txt-section">
        <p>
          Zakup ekoplakietki w Firmie Jurpol to szybki i łatwy proces, który
          umożliwia Ci swobodne poruszanie się w ekologicznych strefach miast
          oraz wspieranie działań na rzecz ochrony środowiska.
        </p>
        <h2 class="cta">Skontaktuj się z nami, a pomożemy Ci w całym procesie.</h2>
      </div>
    </section>
  </div>
</template>
  
<script>
export default {
  name: 'EkoplakietkaPage',
};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  