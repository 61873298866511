<template>
    <div class="green-side">
        <div class="header-container flex-parent">
            <div class="txt-side">
                <h1>KALIBRACJA</h1>
                <p>
                    Kalibracja Szyb Samochodowych z Systemem ADAS - Bezpieczeństwo na
                    Drodze!
                    <br /><br />
                    Firma Jurpol - Lider w Branży Szyb Samochodowych oferuje profesjonalną
                    kalibrację szyb z systemem ADAS. To klucz do zwiększenia
                    bezpieczeństwa na drodze. Nasze usługi kalibracji szyb samochodowych z
                    systemem Advanced Driver Assistance Systems (ADAS) zapewniają
                    bezpieczną jazdę i działanie zaawansowanych systemów bezpieczeństwa w
                    Twoim pojeździe.
                </p>
            </div>
            <div class="top-side">
                <img src="../assets/icons/kalibracja.png" />
            </div>
        </div>
        <section>
            <h2 class="smaller">
                Dlaczego warto skorzystać z naszej usługi kalibracji szyb z systemem
                ADAS?
            </h2>
            <div class="arrows-container">
                <lottie-player class="arrows" src="/arrow.json" background="transparent" speed="1"
                    style="width: 86px; height: 86px" loop autoplay></lottie-player>
            </div>
            <div class="adas-container">
                <img src="../assets/adas.png" class="adas-img" />
                <div class="flex-parent mobile">
                    <div class="icon-container">
                        <img src="../assets/icons/1.png" />
                    </div>
                    <div class="adas-point">
                        <h2>Profesjonalizm i Doświadczenie</h2>
                        <p>
                            Nasz wykwalifikowany zespół specjalistów z długoletnim
                            doświadczeniem zapewnia najwyższą jakość kalibracji.
                        </p>
                    </div>
                </div>
                <div class="flex-parent mobile">
                    <div class="icon-container">
                        <img src="../assets/icons/2.png" />
                    </div>
                    <div class="adas-point">
                        <h2>Sprawdzone Metody Kalibracji</h2>
                        <p>
                            Stosujemy sprawdzone metody i nowoczesne narzędzia, aby zapewnić
                            precyzyjną kalibrację szyb samochodowych.
                        </p>
                    </div>
                </div>
                <div class="flex-parent mobile">
                    <div class="icon-container">
                        <img src="../assets/icons/3.png" />
                    </div>
                    <div class="adas-point">
                        <h2>Usługa Dostosowana do Twojego Pojazdu</h2>
                        <p>
                            Niezależnie od marki i modelu pojazdu, możesz polegać na naszym
                            profesjonalizmie i dświadczeniu.
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <h2 class="smaller" style="padding: 0 2rem;">
            Proces kalibracji szyb samochodowych z systemem ADAS obejmuje:
        </h2>
        <div class="flex-parent" style="align-items: flex-start;">
            <div class="side-adas-txt">
                <div class="flex-parent">
                    <div class="icon-container">
                        <img src="../assets/icons/secure.png" />
                    </div>
                    <div>
                        <h3>Kalibracja Kamer lub Czujników</h3>
                        <p>
                            Precyzyjna kalibracja kamer i czujników montowanych na szybie, co
                            jest kluczowe dla poprawności działania systemów bezpieczeństwa.
                        </p>
                    </div>
                </div>
                <div class="flex-parent">
                    <div class="icon-container">
                        <img src="../assets/icons/test.png" />
                    </div>
                    <div>
                        <h3>Testy i Weryfikacja</h3>
                        <p>
                            Przeprowadzamy dokładne testy, aby upewnić się, że systemy ADAS
                            działają zgodnie z oczekiwaniami.
                        </p>
                    </div>
                </div>
                <div class="flex-parent">
                    <div class="icon-container">
                        <img src="../assets/icons/car-wash.png" />
                    </div>
                    <div>
                        <h3>Raport i Certyfikat</h3>
                        <p>
                            Po zakończeniu kalibracji otrzymujesz raport i certyfikat
                            potwierdzający poprawność kalibracji.
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <img src="../assets/photos/test-car.png" class="car-img" />
            </div>
        </div>
        <section>
            <div class="txt-section">
                <p>
                    Kalibracja szyb samochodowych z systemem ADAS w firmie Jurpol to klucz
                    do bezpiecznej jazdy. Niezależnie od marki i modelu pojazdu możesz
                    polegać na naszym profesjonalizmie i doświadczeniu.
                </p>
                <h2 class="cta">
                    Skorzystaj z naszych usług i ciesz się maksymalnym poziomem
                    bezpieczeństwa na drodze!
                </h2>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    name: 'KalibracjaSzyb',
};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  