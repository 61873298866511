<template>
    <div class="oferta">
        <div class="slider-container">
            <div class="arrow left-arrow" @click="scrollLeft">&lt;</div>
            <div class="green-icons-container" @click="updateContent" ref="iconsContainer">
                <div class="green-wrapper" data-category="osobowe">
                    <img src="../assets/icons/green-circles/osobowe.svg" alt="osobowe" />
                    <h3>OSOBOWE</h3>
                </div>
                <div class="green-wrapper" data-category="premium">
                    <img src="../assets/icons/green-circles/premium.svg" alt="premium" />
                    <h3>PREMIUM</h3>
                </div>
                <div class="green-wrapper" data-category="ciężarowe">
                    <img src="../assets/icons/green-circles/ciezarowe.svg" alt="ciężarowe" />
                    <h3>CIĘŻAROWE</h3>
                </div>
                <div class="green-wrapper" data-category="camper">
                    <img src="../assets/icons/green-circles/camper.svg" alt="camper" />
                    <h3>CAMPER</h3>
                </div>
                <div class="green-wrapper" data-category="oldtimery">
                    <img src="../assets/icons/green-circles/old.svg" alt="YOUNG&OLDTIMERY" />
                    <h3>YOUNG & OLDTIMERY</h3>
                </div>
                <div class="green-wrapper" data-category="specjalistyczne">
                    <img src="../assets/icons/green-circles/specjalistyczne.svg" alt="specjalistyczny pojazd" />
                    <h3>SPECJALISTYCZNE</h3>
                </div>
                <div class="green-wrapper" data-category="rolnicze">
                    <img src="../assets/icons/green-circles/rolnicze.svg" alt="rolnicze" />
                    <h3>ROLNICZE</h3>
                </div>
                <div class="green-wrapper" data-category="budowlane">
                    <img src="../assets/icons/green-circles/budowlane.svg" alt="maszyny budowlane" />
                    <h3>MASZYNY BUDOWLANE</h3>
                </div>
                <div class="green-wrapper" data-category="autobusy">
                    <img src="../assets/icons/green-circles/autobusy.svg" alt="autobus" />
                    <h3>AUTOBUSY </h3>
                </div>
            </div>
            <div class="arrow right-arrow" @click="scrollRight">&gt;</div>
        </div>

        <div class="header-container flex-parent">
            <div class="txt-side">
                <h1>{{ selectedCategory.title }}</h1>
                <p v-html="selectedCategory.description"></p>
            </div>
            <div class="top-side">
                <img :src="selectedCategory.image" alt="car photo" />
            </div>
        </div>
    </div>
</template>
<script>

import { ref } from 'vue';

export default {
    name: 'OfertaSzyby',
    setup() {

        const iconsContainer = ref(null);

        const scrollLeft = () => {
            if (iconsContainer.value) {
                iconsContainer.value.scrollBy({ left: -200, behavior: 'smooth' });
            }
        };

        const scrollRight = () => {
            if (iconsContainer.value) {
                iconsContainer.value.scrollBy({ left: 200, behavior: 'smooth' });
            }
        };

        const categories = {
            osobowe: {
                title: 'OSOBOWE',
                description: `Jurpol jest dostawcą szyb do aut osobowych w zakresie produktów markowych, OEM – szyb logowanych znakiem producenta auta, jak również dostawcą szyb w zamienniku. Serwis szyb w autach osobowych najczęściej obejmuje:
                <ul>
                    <li>sprzedaż oraz wymianę szyby przedniej, bocznej, tylnej,</li>
                    <li>naprawę szyby,</li>
                    <li>kalibrację systemu ADAS,</li>
                    <li>przyciemnianie szyb,</li>
                    <li>naprawa lusterek zewnętrznych.</li>
                </ul>
                Realizujemy usługi w ramach zleceń indywidualnych, zleceń realizowanych z ubezpieczenia Auto Szyba lub AutoCasco oraz zleceń biznesowych. 
                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
                61 810 76 17</a><br><br>
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
               biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/osobowe-glowne.jpg'),
            },
            premium: {
                title: 'PREMIUM',
                description: `Jurpol jest dostawcą szyb do aut premium w zakresie produktów markowych, OEM – szyb logowanych znakiem producenta auta, jak również dostawcą szyb w zamienniku. Nasze doświadczenie i wiedza pozwala nam na realizację usług przy zachowaniu najwyższych standardów zgodnych z najnowocześniejszymi rozwiązaniami technologicznymi. Serwis szyb w autach premium najczęściej obejmuje:
                <ul>
                    <li>sprzedaż oraz wymianę szyby przedniej, bocznej, tylnej,</li>
                    <li>naprawę szyby,</li>
                    <li>kalibrację systemu ADAS,</li>
                    <li>przyciemnianie szyb,</li>
                    <li>naprawa lusterek zewnętrznych.</li>
                </ul>
                Realizujemy usługi w ramach zleceń indywidualnych, zleceń realizowanych z
                <a href="/ubezpieczenia" style="text-decoration: none; color: #4d4d4d;">ubezpieczenia</a>
                Auto Szyba lub AutoCasco oraz zleceń biznesowych.

                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
                61 810 76 17</a>
                <br><br>
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
               biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/premium.jpg'),
            },
            ciężarowe: {
                title: 'CIĘŻAROWE',
                description: `Jurpol jest dostawcą szyb do aut ciężarowych w zakresie produktów markowych, OEM – szyb logowanych znakiem producenta auta, jak również dostawcą szyb w zamienniku. Serwis szyb w autach ciężarowych najczęściej obejmuje:
                <ul>
                    <li>sprzedaż oraz wymianę szyby przedniej, bocznej,</li>
                    <li>naprawę szyby,</li>
                    <li>naprawa lusterek zewnętrznych.</li>
                </ul>
                Realizujemy usługi w ramach zleceń indywidualnych, zleceń realizowanych z ubezpieczenia (link do podstrony ubezpieczenia) AutoCasco oraz zleceń biznesowych. Nasze usługi realizowane są stacjonarnie w naszej siedzibie oraz poprzez serwis mobilny na terenie całej Polski jak również zagranicą. 
                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
               61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
                biuro@jurpol.pl
                </a>`,
                image: require('@/assets/photos/ciezarowe.jpg'),
            },
            camper: {
                title: 'CAMPER',
                description: `Jedną z naszych specjalizacji są usługi dotyczące szyb do kamperów ( wszystkie marki pojazdów półintegra oraz pełna integra ). Nasza oferta obejmuje zarówno sprzedaż szyb, jak i ich wymianę oraz naprawę, zapewniając usługi na najwyższym poziomie. Oferujemy szeroką gamę szyb czołowych do wszystkich marek i modeli kamperów pełna integra.
                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>

                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
            61 810 76 17</a><br><br>

                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
               biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/kamper-glowne.jpg'),
            },
            oldtimery: {
                title: 'YOUNG & OLDTIMERY',
                description: `Jedną z naszych specjalizacji jest obsługa w zakresie dystrybucji i serwisu szyb do pojazdów z minionej epoki tzw. oldtimerów i youngtimerów.
                Pojazdy te zawsze rozgrzewają nasze serca, bo wiemy, że to kolebka motoryzacji europejskiej tworzonej z ogromną pasją i niewątpliwym wpływem na rozwój technologii... te auta mają duszę.
                Serwis szyb samochodowych do young i oldtimerów to specjalistyczna usługa, w ramach której godnie zaopiekujemy się Twoim niesamowitym autem, dostarczymy do niego oryginalną szybę i zamontujemy ją przy zachowaniu najwyższych standardów.
                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
               61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
                biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/young-oldtimer.jpg'),
            },
            specjalistyczne: {
                title: 'SPECJALISTYCZNE',
                description: `Zakres naszych usług obejmuje dystrybucję i serwis szyb do pojazdów specjalistycznych. Oferujemy szyby hartowane i laminowane w wersji szyb płaskich oraz szyb giętych. Produkujemy szyby według dostarczonych wzorów i pod indywidualne wymagania klienta.
                <br><br>
                Oferujemy szyby m.in. do:
                <ul>
                    <li>wózki widłowe,</li>
                    <li>zamiatarki,</li>
                    <li>maszyny specjalistyczne (centra obróbcze, tokarki)</li>
                    <li>inne maszyny.</li>
                </ul>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
               61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
              biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/specjalistyczne.jpg'),
            },
            rolnicze: {
                title: 'ROLNICZE',
                description: `Jedną z naszych specjalizacji jest obsługa w zakresie dystrybucji i serwisu szyb do pojazdów rolniczych. Oferujemy szyby hartowane i laminowane w wersji szyb płaskich oraz szyb giętych. Produkujemy szyby według dostarczonych wzorów jak również zajmujemy się dystrybucją szyb katalogowanych.
                <ul>
                    <li>ciągników rolniczych,</li>
                    <li>kombajnów,</li>
                    <li>ładowarek teleskopowych,</li>
                    <li>inne pojazdy.</li>
                </ul>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
                61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
                biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/rolnicze.jpg'),
            },
            budowlane: {
                title: 'MASZYNY BUDOWLANE',
                description: `Jedną z naszych specjalizacji jest obsługa w zakresie dystrybucji i serwisu szyb do maszyn budowlanych. Oferujemy szyby hartowane i laminowane w wersji szyb płaskich oraz szyb giętych. Produkujemy szyby według dostarczonych wzorów jak również zajmujemy się dystrybucją szyb katalogowanych.
                <br><br>
                Oferujemy szyby m.in. do:
                <ul>
                    <li>koparek,</li>
                    <li>minikoparek,</li>
                    <li>koparko-ładowarek,</li>
                    <li>dźwigów,</li>
                    <li>ładowarek teleskopowych,</li>
                    <li>wozideł,</li>
                    <li>inne pojazdy.</li>
                </ul>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
                61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
                biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/maszyny-budowlane-glowne.jpg'),
            },
            autobusy: {
                title: 'AUTOBUSY',
                description: `Jurpol jest dostawcą szyb do autobusów w pełnej gamie asortymentowej. Oferta szyb do autobusów obejmuje produkty markowe, OEM – szyby logowane znakiem producenta auta, jak również szybyw zamienniku. Serwis szyb w autobusach najczęściej obejmuje:
                <ul>
                    <li>
                        sprzedaż oraz wymianę szyby przedniej, bocznej,
                    </li>
                    <li>
                        naprawę szyby,
                    </li>
                    <li>
                        naprawa lusterek zewnętrznych.
                    </li>
                </ul>
                Realizujemy usługi w ramach zleceń indywidualnych, zleceń realizowanych z ubezpieczenia (link do podstrony ubezpieczenia) AutoCasco oraz zleceń biznesowych. Nasze usługi realizowane są stacjonarnie w naszej siedzibie oraz poprzez serwis mobilny na terenie całej Polski jak również zagranicą.
                <br><br>
                Skontaktuj się z nami w celu weryfikacji Twojej potrzeby, a my zaproponujemy korzystne rozwiązanie<br><br>
                
                <a href="tel:618107617" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="16.434" viewBox="0 0 16.435 16.434">
                <path id="Icon_awesome-phone-alt" data-name="Icon awesome-phone-alt" d="M15.966,11.614l-3.6-1.541a.77.77,0,0,0-.9.221L9.88,12.239A11.9,11.9,0,0,1,4.192,6.552L6.137,4.96a.768.768,0,0,0,.221-.9L4.818.466A.776.776,0,0,0,3.935.02L.6.79a.77.77,0,0,0-.6.751A14.892,14.892,0,0,0,14.894,16.435a.77.77,0,0,0,.751-.6l.77-3.338a.78.78,0,0,0-.45-.886Z" transform="translate(0 0)" fill="#4d4d4d"/>
                </svg>
                61 810 76 17</a><br><br>
                
                <a href="mailto: biuro@jurpol.pl" style="text-decoration: none; color: #4d4d4d;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16.435" height="13.147" viewBox="0 0 16.435 13.147">
                <path id="Icon_material-email" data-name="Icon material-email" d="M17.791,6H4.643A1.641,1.641,0,0,0,3.008,7.643L3,17.5a1.648,1.648,0,0,0,1.643,1.643H17.791A1.648,1.648,0,0,0,19.435,17.5V7.643A1.648,1.648,0,0,0,17.791,6Zm0,3.287L11.217,13.4,4.643,9.287V7.643l6.574,4.109,6.574-4.109Z" transform="translate(-3 -6)" fill="#4d4d4d"/>
                </svg>
                biuro@jurpol.pl</a>`,
                image: require('@/assets/photos/autobus.jpg'),
            },
        };

        const selectedCategory = ref(categories.osobowe);

        const currentCategory = ref('osobowe');

        const updateContent = (event) => {
            const category = event.target.closest('.green-wrapper')?.getAttribute('data-category');
            if (category && categories[category]) {
                selectedCategory.value = categories[category];
                currentCategory.value = category;
            }
        };

        return { selectedCategory, updateContent, scrollLeft, scrollRight, iconsContainer };
    },
};

</script>

<style scoped>
@import "../styles/oferta.css";
</style>
