<template>
  <div class="green-side">
    <div class="header-container flex-parent">
      <div class="txt-side">
        <h1>SERWIS MOBILNY</h1>
        <h2>Wygodny i Szybki Dojazd do Ciebie!</h2>
        <p>
          Firma Jurpol to nie tylko lider w branży szyb samochodowych, ale także
          dostawca wyjątkowego Serwisu Mobilnego, który umożliwia dostarczenie
          naszych usług prosto do Ciebie, w dowolnie wybrane miejsce. Nasz
          Serwis Mobilny to wygodne i efektywne rozwiązanie, które pozwala Ci
          zaoszczędzić cenny czas i uniknąć zbędnych utrudnień.
        </p>
      </div>
      <div class="top-side">
        <img src="../assets/icons/mobile.png" />
      </div>
    </div>
    <section>
      <h2 class="smaller">Dlaczego warto skorzystać z naszego Serwisu Mobilnego?</h2>
      <div class="arrows-container">
        <lottie-player class="arrows" src="/arrow.json" background="transparent" speed="1"
          style="width: 86px; height: 86px" loop autoplay></lottie-player>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/fast.png" />
        </div>
        <div class="why-mobile">
          <h3>Szybkość realizacji</h3>
          <p>
            Nasz Serwis Mobilny działa sprawnie i efektywnie. Dzięki temu możesz
            szybko wrócić na drogę, unikając długich oczekiwań w warsztacie.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/time-and-date.png" />
        </div>
        <div class="why-mobile">
          <h3>Oszczędność czasu klienta</h3>
          <p>
            Jesteśmy świadomi, jak cenny jest Twój czas. Dlatego nasz Serwis
            Mobilny pozwala Ci zaoszczędzić czas, który musiałbyś poświęcić na
            dojazd do tradycyjnego warsztatu.
          </p>
        </div>
      </div>
      <h2 class="smaller">Nasze usługi w ramach Serwisu Mobilnego obejmują:</h2>
      <div class="flex-parent" style="justify-content: center">
        <div class="flex-parent service">
          <img src="../assets/icons/windshield.png" />
          <div>
            <h3>Wymiana Szyb Samochodowych</h3>
            <p>
              Jeśli Twoja szyba wymaga naprawy lub wymiany, przyjedziemy do
              Ciebie, aby zapewnić Ci nową szybę bez konieczności opuszczania
              swojego miejsca.
            </p>
          </div>
        </div>
        <div class="flex-parent service">
          <img src="../assets/icons/car-collision.png" />
          <div>
            <h3>Naprawy Awaryjne</h3>
            <p>
              Nieplanowane uszkodzenia szyb mogą zdarzyć się w najmniej
              odpowiednich momentach. Nasz Serwis Mobilny jest dostępny, aby
              pomóc Ci natychmiast, gdy tego potrzebujesz.
            </p>
          </div>
        </div>
      </div>
      <h2 class="smaller">Jak skorzystać z Serwisu Mobilnego Jurpol?</h2>
      <img src="../assets/icons/nmbsline.png" class="nmb-line" />
      <div class="flex-parent steps">
        <div class="">
          <h3>Skontaktuj się<br />z nami</h3>
          <p>Zadzwoń lub napisz do nas, opisując swoje potrzeby.</p>
        </div>
        <div class="why">
          <h3>Podaj nam lokalizację</h3>
          <p>
            Wskaż, gdzie się znajdujesz lub gdzie chciałbyś, abyśmy przyjechali.
          </p>
        </div>
        <div class="why">
          <h3>Umów termin</h3>
          <p>Razem ustalimy dogodny termin wizyty naszego Serwisu Mobilnego.</p>
        </div>
        <div class="why">
          <h3>Ciesz się wygodą<br />i oszczędnością czasu</h3>
          <p>
            Pozwól nam zająć się Twoimi szybami, a Ty możesz kontynuować swoje
            sprawy bez zbędnego przestoju.
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SerwisMobilny',
};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  