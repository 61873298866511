<template>
  <div class="insurance">
    <div class="section-main">
      <h1>UBEZPIECZENIA</h1>
      <p>
        Jeśli Twoje auto doznało uszkodzenia lub zarysowania szyby, a posiadasz
        ubezpieczenie Auto Szyba, OC lub AutoCasco, to możemy kompleksowo zająć
        się Twoją sprawą. Nasza firma oferuje profesjonalne usługi naprawy lub
        wymiany szyby z ubezpieczenia, bez konieczności ponoszenia kosztów
        (rozliczenie szkody odbywa się bezgotówkowo poprzez Twoje Towarzystwo
        Ubezpieczeń). Wystarczy, że zgłosisz szkodę do nas, a my zajmiemy się
        wszystkimi niezbędnymi formalnościami:
      </p>
      <div class="flex-parent">
        <div class="icon-txt-side">
          <div class="icon-side">
            <img src="../assets/icons/loan.png" />
          </div>
          <p>
            Pomagamy w zgłoszeniu lub pozyskaniu szkody od Twojego
            ubezpieczyciela.
          </p>
        </div>
        <div class="icon-txt-side">
          <div class="icon-side">
            <img src="../assets/icons/searching.png" />
          </div>
          <p>Przeprowadzamy oględziny pojazdu i uszkodzonej szyby.</p>
        </div>
      </div>
      <br />
      <p>
        Wszystko, co musisz zrobić, to zgłosić szkodę w swoim towarzystwie
        ubezpieczeniowym i wskazać Jurpol Sp. z o.o. Sp. k. jako preferowaną
        firmę do likwidacji szkody oraz przekazać nam numer szkody.
      </p>
      <br /><br />
      <h3>Naprawa i Wymiana Szyby Samochodowej z polisy Autocasco i *OC</h3>
      <p>
        Jeśli masz polisę Autocasco i chciałbyś, aby to firma Jurpol zgłosiła
        szkodę, załatwiając wszystkie formalności z ubezpieczycielem w Twoim
        imieniu, to przed skontaktowaniem się z nami przygotuj:
      </p>
      <ul>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />Nazwę
          ubezpieczyciela i numer polisy oraz skan polisy,
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />Skan
          lub zdjęcie dowodu rejestracyjnego pojazdu,
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />Cesję
          (do pobrania -> wzór dla firm, wzór dla osoby fizycznej),
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />Informacje, która szyba została uszkodzona,
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          Informacje dotyczące przyczyny powstania szkody i okoliczności
          zdarzenia,
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          Dane kontaktowe zgłaszającego, w tym numer telefonu, adres e-mail i
          adres zamieszkania,
        </li>
        <li>
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />*oświadczenie sprawcy szkody OC.
        </li>
      </ul>
      <p>
        <b>
          Jeśli szkoda została już zgłoszona do Twojego ubezpieczyciela,
          przygotuj:
        </b>
      </p>
      <div class="numbers-wrapper flex-parent">
        <div class="numbers-container">
          <img src="../assets/icons/1.png" />
          <p>Nazwę ubezpieczyciela i numer szkody,</p>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/2.png" />
          <p>Skan lub zdjęcie dowodu rejestracyjnego pojazdu,</p>
        </div>
        <div class="numbers-container flex-container">
          <img src="../assets/icons/3.png" />
          <p>Cesję</p>
          <a class="download-btn" href="/assets/downloads/Cesja-druk-os-fizyczna-oswiadczenie.pdf"
            download="Cesja-druk-os-fizyczna-oswiadczenie.pdf" target="_blank">Osoba fizyczna
            <img src="../assets/icons/download.svg" /></a>
          <a class="download-btn" href="/assets/downloads/Cesja-druk-firma-oswiadczenie.pdf"
            download="Cesja-druk-firma-oswiadczenie.pdf" target="_blank">Firma <img
              src="../assets/icons/download.svg" /></a>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/4.png" />
          <p>Informacje, która szyba została uszkodzona,</p>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/5.png" />
          <p>
            Dane kontaktowe zgłaszającego, w tym numer telefonu, adres e-mail i
            adres zamieszkania.
          </p>
        </div>
      </div>
      <h3>Naprawa i Wymiana Szyby Samochodowej z polisy Autoszyba</h3>
      <p>
        Jeśli posiadasz polisę Autoszyba i wystąpiła szkoda, prosimy o pierwszy
        kontakt z Twoim towarzystwem ubezpieczeniowym, aby zgłosić szkodę i
        wskazać firmę Jurpol Sp. o.o. Sp. k. jako preferowanego likwidatora
        szkody. To Twój ubezpieczyciel podejmuje decyzję o wyborze warsztatu i
        rodzaju montowanej części (zamiennika dotychczasowej szyby z gwarancją
        jakości OEM). Przed skontaktowaniem się z nami, przygotuj:
      </p>
      <div class="numbers-wrapper flex-parent">
        <div class="numbers-container">
          <img src="../assets/icons/1.png" />
          <p>Nazwę ubezpieczyciela i numer szkody,</p>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/2.png" />
          <p>Skan lub zdjęcie dowodu rejestracyjnego pojazdu,</p>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/3.png" />
          <p>Cesję</p>
          <a class="download-btn"
            href="./assets/downloads/Cesja - druk-os fizyczna-oświdczenie do odebrania odszkodowania.pdf"
            download="Cesja - druk-os fizyczna-oświdczenie do odebrania odszkodowania.pdf" target="_blank">Osoba
            fizyczna
            <img src="../assets/icons/download.svg" /></a>
          <a class="download-btn"
            href="./assets/downloads/Cesja - druk-os firma-oświdczenie do odebrania odszkodowania.pdf"
            download="Cesja - druk-os firma-oświdczenie do odebrania odszkodowania.pdf" target="_blank">Firma <img
              src="../assets/icons/download.svg" /></a>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/4.png" />
          <p>Informacje, która szyba została uszkodzona,</p>
        </div>
        <div class="numbers-container">
          <img src="../assets/icons/5.png" />
          <p>
            Dane kontaktowe zgłaszającego, w tym numer telefonu, adres e-mail i
            adres zamieszkania.
          </p>
        </div>
      </div>
      <h3>
        Realizacja usługi odbywa się po umówionym terminie – zaplanuj swój czas,
        a my zajmiemy się resztą.
      </h3>
      <p>
        Współpracujemy z czołowymi towarzystwami ubezpieczeniowymi i realizujemy
        usługę naprawy i wymiany szyby z ubezpieczenia
        <a href="https://www.pzu.pl/dla-ciebie-i-rodziny/majatek-podroze-oc/pojazdy/pzu-auto-szyba"
          rel="noopener noreferrer" target="_blank" style="color: var(--accent-color)">
          PZU
        </a>,
        <a href="https://www.link4.pl/ubezpieczenie-szyb" rel="noopener noreferrer" target="_blank"
          style="color: var(--accent-color)">LINK4</a>, <a href="https://www.warta.pl/ubezpieczenie-szyb-samochodowych/"
          rel="noopener noreferrer" target="_blank" style="color: var(--accent-color)">
          WARTA
        </a>,
        <a href="https://www.ergohestia.pl/centrum-wiedzy/ubezpieczenie-szyb-samochodu-czy-warto-wykupic/"
          rel="noopener noreferrer" target="_blank" style="color: var(--accent-color)">
          ErgoHESTIA
        </a>,
        <a href="https://www.allianz.pl/pl_PL/dla-ciebie/samochod/ubezpieczenie-szyb.html" rel="noopener noreferrer"
          target="_blank" style="color: var(--accent-color)">
          ALLIANZ
        </a>,
        <a href="https://tuz.pl/produkty/autoszyby/?utm_term=&utm_campaign=&utm_source=adwords&utm_medium=ppc&hsa_acc=7300799895&hsa_cam=20753076127&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAqsitBhDlARIsAGMR1RjPswWPvZrZ7n0Hz3s7FlYjjLS1lXAHHEN3hLOrXUyzkQPzhZ1zL58aApm8EALw_wcB"
          rel="noopener noreferrer" target="_blank" style="color: var(--accent-color)">
          TUZ
        </a>,
        <a href="https://www.generali.pl/dla-ciebie/autoszyby" rel="noopener noreferrer" target="_blank"
          style="color: var(--accent-color)">
          GENERALI
        </a>,
        <a href="https://wiener.pl/ubezpieczenia-auto" rel="noopener noreferrer" target="_blank"
          style="color: var(--accent-color)">
          WIENER
        </a>,
        <a href="https://www.uniqa.pl/ubezpieczenie-oc/ubezpieczenie-szyb/" rel="noopener noreferrer" target="_blank"
          style="color: var(--accent-color)">
          UNIQA
        </a>
        i INNE.
      </p>
      <br />
      <h3>
        Jesteśmy gotowi pomóc Ci w naprawie lub wymianie szyby samochodowej z
        ubezpieczenia. Działamy sprawnie i profesjonalnie, dbając o Twój komfort
        i bezpieczeństwo.
      </h3>
      <br /><br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UbezpieczeniaPage',
};
</script>

<style scoped>
@import "../styles/ubezpieczenia.css";
</style>