import { createRouter, createWebHistory } from "vue-router";

import HomePage from "../views/HomePage.vue";
import AboutPage from "../views/AboutPage.vue";
import KarieraPage from "../views/KarieraPage.vue";
// import RgsPage from "../views/RgsPage.vue";
import OfertaSzyby from "../views/OfertaSzyby.vue";
import SerwisMobilny from "../views/SerwisMobilny.vue";
import NaprawaSzyb from "../views/NaprawaSzyb.vue";
import SerwisCamper from "../views/SerwisCamper.vue";
import AkcesoriaPage from "../views/AkcesoriaPage.vue";
import InsideGarage from "../views/InsideGarage.vue";
import KalibracjaSzyb from "../views/KalibracjaSzyb.vue";
import EkoplakietkaPage from "../views/EkoplakietkaPage.vue";
import PrzyciemnianieSzyb from "../views/PrzyciemnianieSzyb.vue";
import UbezpieczeniaPage from "../views/UbezpieczeniaPage.vue";
import KontaktPage from "../views/KontaktPage.vue";
import BlogPage from "../views/BlogPage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/o-nas",
    name: "AboutPage",
    component: AboutPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/dolacz-do-nas",
    name: "KarieraPage",
    component: KarieraPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  // {
  //   path: "/rgs",
  //   name: "RgsPage",
  //   component: RgsPage,
  //   meta: {
  //     title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
  //     metaTags: [
  //       {
  //         name: 'description',
  //         content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
  //       },
  //       {
  //         name: 'keywords',
  //         content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
  //       }
  //     ]
  //   }
  // },
  {
    path: "/oferta-szyby",
    name: "OfertaSzyby",
    component: OfertaSzyby,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/serwis-mobilny",
    name: "SerwisMobilny",
    component: SerwisMobilny,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/naprawa-szyb",
    name: "NaprawaSzyb",
    component: NaprawaSzyb,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/serwis-camper",
    name: "SerwisCamper",
    component: SerwisCamper,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/akcesoria",
    name: "AkcesoriaPage",
    component: AkcesoriaPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/inside-garage",
    name: "InsideGarage",
    component: InsideGarage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/kalibracja-szyb",
    name: "KalibracjaSzyb",
    component: KalibracjaSzyb,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/ekoplakietka",
    name: "EkoplakietkaPage",
    component: EkoplakietkaPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/przyciemnianie-szyb",
    name: "PrzyciemnianieSzyb",
    component: PrzyciemnianieSzyb,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/ubezpieczenia",
    name: "UbezpieczeniaPage",
    component: UbezpieczeniaPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/kontakt",
    name: "KontaktPage",
    component: KontaktPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
  {
    path: "/blog",
    name: "BlogPage",
    component: BlogPage,
    meta: {
      title: 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.',
      metaTags: [
        {
          name: 'description',
          content: 'Potrzebujesz naprawy lub wymiany szyby samochodowej? JURPOL Szyby samochodowe jest liderem w branży motoryzacyjnej zajmującym się serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach osobowych, autach premium, ciężarowych, szynowych, przemysłowych, rolniczych, a także zabytkowych.'
        },
        {
          name: 'keywords',
          content: 'auto szyby poznań jurpol, szyby samochodowe poznań, jurpol szyby samochodowe, jurpol komorniki, szyby samochodowe, wycieraczki samochodowe, szyby do samochodów osobowych, szyby do samochodów dostawczych, szyby do samochodów ciężarowych, szyby do maszyn budowlanych, szyby do autobusów, szyby do pojazdów specjalistycznych, szyby do youngtimerów, szyby do oldtimerów, szyby do pojazdó rolniczych, naprawa szyby samochodowej, naprawa odprysku, montaż szyb samochodowych, szyby do autobusów, kabilbracja szyb'
        }
      ]
    }
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to) => {

  document.title = to.meta.title || 'Jurpol Szyby samochodowe - szyby samochodowe poznań, montaż szyb, naprawa szyb, naprawa odprysku, sprzedaż szyb.';

  const metaTags = to.meta.metaTags || [];
  metaTags.forEach(tag => {
    let tagElement = document.querySelector(`meta[name="${tag.name}"]`);
    if (!tagElement) {
      tagElement = document.createElement('meta');
      tagElement.setAttribute('name', tag.name);
      document.head.appendChild(tagElement);
    }
    tagElement.setAttribute('content', tag.content);
  });
});

export default router;
