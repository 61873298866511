<template>
  <div class="about-page">
    <div class="txt">
      <h1>
        <b>JURPOL - </b>LIDER W BRANŻY SZYB SAMOCHODOWYCH I USŁUG MONTAŻU ORAZ
        WYMIANY Z PONAD 30-LETNIM DOŚWIADCZENIU.
      </h1>
      <div class="flex-parent numbers">
        <div class="border number-c">
          <img src="../assets/30.png" />
        </div>
        <div class="border number-c">
          <img src="../assets/3000.png" />
        </div>
        <div class="number-c">
          <img src="../assets/5000.png" />
        </div>
      </div>
      <p>
        Nasza firma to <b>niekwestionowany ekspert na rynku motoryzacyjnym</b>,
        z ogromną pasją do samochodów oraz wyjątkowym zrozumieniem potrzeb
        klientów.
        <b>Naszym priorytetem jest świadczenie usług najwyższej jakości w
          zakresie montażu szyb samochodowych, obsługując zarówno klientów
          indywidualnych, jak i biznesowych.</b>
      </p>
    </div>
    <div class="flex-parent">
      <div class="dark grey">
        <img src="../assets/icons/customer.svg" />
        <h2>Dla klientów indywidualnych:</h2>
        <p>
          Profesjonalne podejście do Twojego samochodu - Jesteśmy dumni z
          naszego ponad 30-letniego doświadczenia, które pozwala nam zrozumieć,
          że Twój samochód to nie tylko pojazd, to część Twojego życia. Dlatego
          też podejmujemy się naprawy i wymiany szyb z najwyższą starannością i
          dbałością o każdy detal. Twoje bezpieczeństwo i komfort jazdy są dla
          nas priorytetem.
        </p>
      </div>
      <div class="light grey">
        <img src="../assets/icons/business-and-trade.svg" />
        <h2>Dla klientów biznesowych:</h2>
        <p>
          Profesjonalne podejście do Twojej floty pojazdów - Rozumiemy, jak
          ważna jest sprawność Twojej floty pojazdów dla sukcesu Twojej firmy.
          Nasze usługi są dostosowane do potrzeb biznesowych klientów. Oferujemy
          terminowość, niezawodność i konkurencyjne ceny, które pozwolą Ci
          utrzymać flotę w doskonałej kondycji i minimalizować przestojów.
        </p>
      </div>
    </div>
    <div class="points-section">
      <h1>Czym wyróżniamy się na tle konkurencji?</h1>
      <div class="parent">
        <div class="div1">
          <img src="../assets/icons/customer-review.svg" />
          <h2>Ponad 30 lat doświadczenia</h2>
          <p>
            Jesteśmy na rynku od ponad trzech dekad, co przekłada się na naszą
            niezawodność i rozległe doświadczenie w branży, co jest kluczem do
            naszego sukcesu.
          </p>
        </div>
        <div class="div2">
          <img src="../assets/icons/high-quality.svg" />
          <h2>Jakość nadrzędna</h2>
          <p>
            Nasza firma jest znana z doskonałej jakości produktów i usług. Każdy
            projekt traktujemy priorytetowo, z dbałością o najdrobniejsze
            detale, aby dostarczyć naszym klientom trwałe i bezpieczne
            rozwiązania.
          </p>
        </div>
        <div class="div3">
          <img src="../assets/icons/collaboration.svg" />
          <h2>Wyszkolony i profesjonalny zespół</h2>
          <p>
            Nasza kadra to zespół specjalistów o bogatym doświadczeniu, gotowych
            do obsługi nawet najbardziej wymagających klientów. Nasi pracownicy
            zawsze chętnie udzielają fachowych porad i pomagają rozwiązać
            wszelkie problemy związane z szybami samochodowymi.
          </p>
        </div>
        <div class="div4">
          <img src="../assets/icons/user.svg" />
          <h2>Niezawodność i profesjonalizm</h2>
          <p>
            Nasza firma słynie z niezawodności i profesjonalizmu. Realizujemy
            projekty sprawnie i terminowo, zapewniając naszym klientom usługi na
            najwyższym poziomie.
          </p>
        </div>
        <div class="div5">
          <img src="../assets/icons/location.svg" />
          <h2>Sieć salonów obsługi</h2>
          <p>
            Dostępność naszych usług w wielu lokalizacjach pozwala nam być
            jeszcze bliżej naszych klientów.
          </p>
        </div>
        <div class="div6">
          <img src="../assets/icons/offer.svg" />
          <h2>Szeroki zakres oferty</h2>
          <p>
            Nasza oferta obejmuje szyby samochodowe do pojazdów różnych marek i
            modeli. Bez względu na rodzaj pojazdu, jesteśmy gotowi spełnić Twoje
            oczekiwania związane z wymianą, naprawą lub przyciemnianiem szyb
            samochodowych.
          </p>
        </div>
      </div>
    </div>
    <div class="flex-parent">
      <!-- <div class="logo-big-container">
        <img src="../assets/logo/logo.png" alt="Logo" class="logo-big" />
      </div> -->
      <div class="text-container">
        <p>
          Jeśli poszukujesz specjalistów w dziedzinie szyb samochodowych, którzy
          łączą w sobie ponad 30 lat doświadczenia, niezawodność, jakość,
          dbałość o szczegóły oraz szeroki zakres usług, to nasza firma to
          idealny partner, bez względu na to, czy jesteś klientem indywidualnym,
          czy biznesowym. Jesteśmy gotowi spełnić Twoje oczekiwania i zapewnić
          Ci usługi na najwyższym poziomie.
        </p>
        <p class="green-cta">
          Dołącz do grona naszych zadowolonych klientów i zaufaj
          profesjonalizmowi firmy Jurpol!
        </p>
        <a href="/dolacz-do-nas.html" class="button" style="margin-top: 0;">Dołącz</a>
      </div>
    </div>
    <div class="memories">
      <div class="memories-content">
        <h1>HISTORIA FIRMY</h1>
        <img src="../assets/icons/dates.png" class="dates" />
        <div class="mobile-wrapper">
          <div class="mobile-container">
            <img src="../assets/years/1989.png" />
            <p>założenie firmy Jurpol – Szyby Samochodowe Jolanta Jóskowiak</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/1993.png" />
            <p>budowa siedziby w Komornikach</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/1996.png" />
            <p>otwarcie filii Poznań Gorczyn na ul. Kordeckiego</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/1999.png" />
            <p>
              zmiana nazwy na Jurpol Centrum Montażu i Sprzedaży szyb
              samochodowych
            </p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2000.png" />
            <p>
              otwarcie filli Poznań Rondo Obornickie ul. Lechicka 2 oraz
              uruchomienie serwisu mobilnego
            </p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2003.png" />
            <p>otwarcie filli Września ul. Włocławska 1a</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2004.png" />
            <p>otwarcie filli Kalisz</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2005.png" />
            <p>otwarcie filli w Bydgoszczy</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2005-2015.png" />
            <p>budowa struktury sprzedaży hurtowej</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2009.png" />
            <p>
              rebranding oraz przekształcenie nazwy i formy prawnej na Jurpol
              Sp. z o.o. Sp. k.
            </p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2012.png" />
            <p>przebudowa magazynu na magazyn wysokiego składowania</p>
          </div>
          <div class="mobile-container">
            <img src="../assets/years/2019.png" />
            <p>inwestycja w unowocześnienie zaplecza serwisowego</p>
          </div>
        </div>
      </div>
    </div>
    <div class="grey-bcg">
      <div class="memories-content">
        <div class="flex-parent">
          <div class="timeline-container">
            <img src="../assets/photos/old-logo.png" />
            <p>
              1999 r. – zmiana nazwy na Jurpol Centrum Montażu i Sprzedaży szyb
              samochodowych sp.j.
            </p>
          </div>
          <div class="timeline-container">
            <img src="../assets/photos/rondo.png" />
            <p>
              2000 r. – otwarcie filli Poznań Rondo Obornickie ul. Lechicka 2
              oraz uruchomienie serwisu mobilnego
            </p>
          </div>
          <div class="timeline-container">
            <img src="../assets/photos/filia.png" />
            <p>2003 r. – otwarcie filli Września ul. Włocławska 1a</p>
          </div>
          <div class="timeline-container">
            <img src="../assets/photos/tradycja.png" />
            <p>
              2005 r.-2015 r. – budowa struktury sprzedaży hurtowej
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AboutPage',
};
</script>

<style scoped>
@import "../styles/about.css";
</style>