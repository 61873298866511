<template>
  <div class="green-side">
    <div class="header-container flex-parent">
      <div class="txt-side">
        <h1>NAPRAWA SZYB SAMOCHODOWYCH</h1>
        <p>
          Firma Jurpol to lider w dziedzinie naprawy szyb samochodowych,
          oferujący kompleksowe usługi naprawy odprysków i uszkodzeń szyb w
          różnorodnych pojazdach oraz usługi polerowania szyb. Nasza usługa
          naprawy szyb jest dostosowana do najwyższych standardów, zapewniając
          trwałe i bezpieczne rozwiązania dla Twojego pojazdu.
        </p>
      </div>
      <div class="top-side">
        <img src="../assets/icons/naprawa.png" />
      </div>
    </div>
    <section>
      <h2 class="smaller">
        Dlaczego warto wybrać naszą usługę naprawy szyb<br />samochodowych?
      </h2>
      <div class="arrows-container">
        <lottie-player class="arrows" src="arrow.json" background="transparent" speed="1"
          style="width: 86px; height: 86px" loop autoplay></lottie-player>
      </div>
      <div class="flex-parent why-wrapper">
        <div class="why-container">
          <img src="../assets/icons/fast.png" />
          <h2>Szybkość Realizacji</h2>
          <p>
            - Nasz wykwalifikowany zespół specjalistów z długoletnim
            doświadczeniem zapewnia najwyższą jakość kalibracji.
          </p>
        </div>
        <div class="why-container">
          <img src="../assets/icons/money.png" />
          <h2>Oszczędność</h2>
          <p>
            - Stosujemy sprawdzone metody i nowoczesne narzędzia, aby zapewnić
            precyzyjną kalibrację szyb samochodowych.
          </p>
        </div>
        <div class="why-container">
          <img src="../assets/icons/road.png" />
          <h2>Bezpieczeństwo na Drodze</h2>
          <p>
            - Niezależnie od marki i modelu pojazdu, możesz polegać na naszym
            profesjonalizmie i doświadczeniu.
          </p>
        </div>
      </div>
      <h2 class="smaller">Nasza usługa naprawy szyb obejmuje:</h2>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Naprawę Mikrouszkodzeń</h2>
            <p>
              - Niebagatelne mikrouszkodzenia mogą prowadzić do większych problemów.
              Nasza naprawa skutecznie usuwa mikrouszkodzenia.
            </p>
          </div>
        </div>
      </div>
      <div class="arrow-points">
        <div class="flex-parent">
          <img class="right-arrow" src="../assets/icons/right-arrow.svg" />
          <div class="arrow-point-container">
            <h2>Profesjonalizm i Precyzja</h2>
            <p>
              - Nasz zespół składa się z wykwalifikowanych specjalistów, którzy
              dokładają wszelkich starań, aby naprawa była przeprowadzona z
              najwyższą precyzją.
            </p>
          </div>
        </div>
      </div>
      <h2 class="smaller">Jak skorzystać z naszej usługi naprawy szyb samochodowych?</h2>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/1.png" />
        </div>
        <div class="how-use">
          <h2>Weryfikacja i Ocena Uszkodzenia</h2>
          <p>
            Po kontakcie z nami, nasz specjalista dokładnie oceni uszkodzenie
            pod kątem możliwości naprawy. Będziemy informować Cię o zakresie
            naprawy oraz ewentualnych kosztach.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/2.png" />
        </div>
        <div class="how-use">
          <h2>Umów Termin Naprawy</h2>
          <p>
            Razem ustalimy dogodny termin naprawy szyby w naszym warsztacie.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/3.png" />
        </div>
        <div class="how-use">
          <h2>Naprawa na Miejscu</h2>
          <p>
            W przypadku mniejszych uszkodzeń, możemy przeprowadzić naprawę szyb
            na miejscu zaraz po weryfikacji pojazdu.
          </p>
        </div>
      </div>
      <div class="flex-parent">
        <div class="icon-container">
          <img src="../assets/icons/4.png" />
        </div>
        <div class="how-use">
          <h2>Ciesz się Klarowną i Bezpieczną Szybą</h2>
          <p>
            Po zakończeniu naprawy szyby, możesz cieszyć się komfortem i
            bezpieczeństwem jazdy.
          </p>
        </div>
      </div>
      <div class="txt-section">
        <p>
          Firma Jurpol to pewność profesjonalnego przyciemniania szyb
          samochodowych, z dbałością o detale i oczekiwania klientów.
        </p>
        <h2 class="cta">
          Skorzystaj z naszych usług i ciesz się ochroną, prywatnością i
          eleganckim wyglądem swojego pojazdu.
        </h2>
      </div>
    </section>
  </div>
</template> 
<script>

export default {
  name: 'NaprawaSzyb',

};
</script>
  
<style scoped>
@import "../styles/green-side.css";
</style>
  