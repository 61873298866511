<template>
    <div v-if="showConsent" class="cookie-consent">
        <div class="consent-content">
            <p>Ta strona korzysta z ciasteczek (cookies), aby świadczyć usługi na najwyższym poziomie. Dalsze
                korzystanie ze
                strony oznacza, że zgadzasz się na ich użycie.</p>
            <button @click="acceptCookies">Zgadzam się</button>
            <button @click="declineCookies">Nie zgadzam się</button>
        </div>
    </div>
</template>

<script>
/* global WHCreateCookie, WHReadCookie, dataLayer */


export default {
    name: 'CookieConsent',
    data() {
        return {
            showConsent: false,
        };
    },
    methods: {
        acceptCookies() {
            WHCreateCookie('cookies_accepted', 'T', 365);
            this.initializeTrackingAndPersonalization();
            this.showConsent = false;
        },
        declineCookies() {
            WHCreateCookie('cookies_accepted', 'F', 365);
            this.showConsent = false;
        },
        initializeTrackingAndPersonalization() {
            // Check if Google Analytics script is already loaded
            if (!window.dataLayer) {
                // Dynamically insert the Google Analytics script
                const script = document.createElement('script');
                script.async = true;
                script.src = 'https://www.googletagmanager.com/gtag/js?id=G-R86K0KJF37';
                document.head.appendChild(script);

                // Initialize `dataLayer` and configure GA after the script is loaded
                script.onload = () => {
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('config', 'G-R86K0KJF37', { 'anonymize_ip': true }); // Enable IP anonymization
                };
            }
        }
    },
    mounted() {
        this.showConsent = WHReadCookie('cookies_accepted') !== 'T';
        if (this.showConsent) {
            this.initializeTrackingAndPersonalization();
        }
    }
};
</script>

<style>
.cookie-consent {
    border-top: 1px solid rgb(171, 170, 170);
    ;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    padding: 20px;
    text-align: center;
    z-index: 1000;
}

.consent-content button {
    border: none;
    padding: .5rem 1rem;
    border-radius: 10px;
    margin: .5rem;
}

.consent-content button:hover {
    cursor: pointer;
    background-color: rgb(171, 170, 170);
}
</style>