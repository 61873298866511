<template>
  <div>
    <AppNavbar />
    <router-view />
    <AppFooter />
    <CookieConsent />
    <StickyForm />
  </div>
</template>

<script>
import AppNavbar from '@/components/AppNavbar.vue';
import AppFooter from '@/components/AppFooter.vue';
import CookieConsent from '@/components/CookieConsent.vue';
import StickyForm from '@/components/StickyForm.vue';

export default {
  name: 'App',
  components: {
    AppNavbar,
    AppFooter,
    CookieConsent,
    StickyForm,
  },
};
</script>

<style>

</style>

