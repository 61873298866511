<template>
    <div class="contact-page">
        <h1><b> Masz pytanie? </b><br />Jesteśmy do dyspozycji!</h1>
        <div class="icons-wrapper flex-parent">
            <div class="icon-container">
                <img src="../assets/icons/kontakt/siedziba.svg" alt="" />
                <h3>
                    <b>Siedziba firmy<br /></b>
                    ul. Wirowska 30,<br />62-052 Komorniki
                </h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/kontakt/obsluga.svg" alt="" />
                <h3>
                    <b>Obsługa Klienta<br /></b>
                    <a href="tel:618107617">61 810 76 17</a>
                </h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/kontakt/email.svg" alt="" />
                <h3>
                    <b>Adres e-mail<br /></b>
                    <a href="mailto: biuro@jurpol.pl">biuro@jurpol.pl</a>
                </h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/kontakt/serwis.svg" alt="" />
                <h3>
                    <b>Serwis mobilny<br /></b>
                    <a href="tel:618107617">61 810 76 17</a>
                </h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/kontakt/facebook.svg" alt="" />
                <h3>
                    <b>Facebook<br /></b>
                    <a href="https://www.facebook.com/jurpol.szybysamochodowe/">
                        @jurpol.szybysamochodowe</a>
                </h3>
            </div>
        </div>

        <!-- <div id="map" style="height: 400px;"></div> -->

        <h2>Nasze lokalizacje</h2>

        <div class="grid-parent">

            <div class="flex-parent-map">


                <div class="local-container div1">
                    <div class="map-side-txt">
                        <h2>Centrala Komorniki</h2>
                        <div class="flex-parent side">
                            <div class="contact-side">
                                <p>
                                    ul. Wirowska 30<br />
                                    62-052 Komorniki<br />
                                    tel.: 61 810 76 17,<br />
                                    email: biuro@jurpol.pl
                                </p>
                            </div>
                            <div class="contact-side">
                                <p>
                                    Dni otwarcia:<br />
                                    pon-pt:
                                    8:00 - 18:00<br />
                                    sob:
                                    8:00 - 14:00
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="map-side">
                        <img src="../assets/map/centrala.png">
                        <a href="https://www.google.com/maps/dir//Wirowska+30,+62-052+Komorniki/@52.3417184,16.7555759,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47044fecf25d3003:0xdeccc214da42f5da!2m2!1d16.8379758!2d52.3416422?hl=pl&entry=ttu"
                            target="_blank" class="road">
                            Wyznacz trasę
                        </a>
                    </div>
                </div>

                <div class="local-container div2">
                    <div class="map-side-txt">
                        <h2>Rondo Obornickie</h2>
                        <div class="flex-parent side">
                            <div class="contact-side">
                                <p>
                                    ul. Lechicka 2<br />
                                    61-695 Poznań<br />
                                    tel.: 61 828 01 39<br />
                                    email: biuro@jurpol.pl
                                </p>
                            </div>
                            <div class="contact-side">
                                <p>
                                    Dni otwarcia:<br />
                                    pon-pt:
                                    8:00 - 17:00<br />
                                    sob:
                                    8:00 - 14:00
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="map-side">
                        <img src="../assets/map/rondo.png">
                        <a href="https://www.google.com/maps/dir//Lechicka+2,+61-298+Pozna%C5%84/@52.4281853,16.962154,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47045c9c28172b7d:0x1572e6cfcf8e5697!2m2!1d16.9647289!2d52.4281821?hl=pl&entry=ttu"
                            target="_blank" class="road">
                            Wyznacz trasę
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex-parent-map">
                <div class="local-container div3">
                    <div class="map-side-txt">
                        <h2>Poznań - Górczyn</h2>
                        <div class="flex-parent side">
                            <div class="contact-side">
                                <p>
                                    ul. Kordeckiego 38 B<br />
                                    60-144 Poznań<br />
                                    biuro obsługi klienta: 61 861 01 52<br />
                                    email: biuro@jurpol.pl<br />
                                </p>
                            </div>
                            <div class="contact-side">
                                <p>Tymczasowo zamknięte</p>
                            </div>
                        </div>
                    </div>
                    <div class="map-side">
                        <img src="../assets/map/gorczyn.png">
                        <a href="https://www.google.com/maps/dir//Kordeckiego+38B,+Pozna%C5%84/@52.3842524,16.8742358,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x470445254e2cd6f7:0x2393231f4998c927!2m2!1d16.8768107!2d52.3842492?hl=pl&entry=ttu"
                            target="_blank" class="road">Wyznacz trasę</a>
                    </div>
                </div>

                <div class="local-container div4">
                    <div class="map-side-txt">
                        <h2>Września</h2>
                        <div class="flex-parent side">
                            <div class="contact-side">
                                <p>
                                    ul. Wrocławska 1A<br />
                                    (stacja benzynowa SHELL)<br />
                                    62-300 Września<br />
                                    tel.: 61 436 91 98<br />
                                    email: biuro@jurpol.pl
                                </p>
                            </div>
                            <div class="contact-side">
                                <p>
                                    Dni otwarcia:<br />
                                    pon-pt:
                                    9:00 - 17:00<br />
                                    sob:
                                    9:00 - 14:00
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="map-side" style="text-align: center;">
                        <img src="../assets/map/wrzesnia.png">
                        <a href="https://www.google.com/maps/dir//Wroc%C5%82awska+1A,+62-302+Wrze%C5%9Bnia/@52.320825,17.560999,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x4704e971f8f6d31b:0xc73dcb2c386dc64b!2m2!1d17.5609991!2d52.3208245?hl=pl&entry=ttu"
                            target="_blank" class="road">Wyznacz trasę</a>
                    </div>
                </div>
            </div>

            <div class="flex-parent-map">

                <div class="local-container div5" style="height: max-content;">
                    <h2>Bydgoszcz</h2>
                    <div class="flex-parent side">
                        <div class="contact-side">
                            <p>
                                <b>Dyżur:</b><br />
                                montaże ustalane telefonicznie<br />
                                <b>Kontakt:</b><br />
                                tel.: 52 333 19 65<br />
                                email: biuro@jurpol.pl
                            </p>
                        </div>
                    </div>
                </div>
                <div class="local-container div6" style="height: max-content;">
                    <h2>Kalisz</h2>
                    <div class="flex-parent side">
                        <div class="contact-side">
                            <p>
                                <b>Dyżur:</b><br />
                                montaże ustalane telefonicznie<br />
                                <b>Kontakt:</b><br />
                                tel.: 662 195 780<br />
                                email: biuro@jurpol.pl
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="green-bcg">
            <div class="txt">
                <h1>SERWIS MOBILNY</h1>
                <h2>Jesteśmy gotowi pomóc,<br />gdziekolwiek jesteś!</h2>
                <div class="container">
                    <a href="tel:61-810-76-17">
                        <img src="../assets/icons/green-circles/phone-call.svg" alt="" class="phone-call" />
                    </a>
                    <a href="mailto: biuro@jurpol.pl">
                        <img src="../assets/icons/green-circles/email.svg" alt="" class="email" />
                    </a>
                </div>
            </div>
            <div class="container">
                <img src="../assets/icons/serwis-mobilny-car.png" alt="" class="mobile-car" />
            </div>
        </div>
    </div>
</template>

<script>

// import 'leaflet/dist/leaflet.css';

// import { onMounted, onUnmounted, ref } from 'vue';
// import L from 'leaflet';

// delete L.Icon.Default.prototype._getIconUrl;

// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
// });

// import mapMarkerIcon from '@/assets/icons/map-marker.png';

export default {
    name: 'KontaktPage',

    // setup() {
    //     const map = ref(null);

    //     onMounted(() => {

    //         map.value = L.map('map').setView([52.320903166592174, 17.561009826123637], 15);

    //         L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    //             attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    //         }).addTo(map.value);

    //         const customIcon = L.icon({
    //             iconUrl: mapMarkerIcon,
    //             iconSize: [32, 48], // Size of the icon w and h
    //             iconAnchor: [15, 15], // Point of the icon which will correspond to marker's location
    //             popupAnchor: [-3, -76] // Point from which the popup should open relative to the iconAnchor
    //         });

    //         L.marker([52.34174704872275, 16.837965068454928], { icon: customIcon }).addTo(map.value).bindPopup('Centrala Komorniki<br>ul. Wirowska 30<br>62-052 Komorniki');

    //         L.marker([52.4426990481144, 16.91025021079007], { icon: customIcon }).addTo(map.value).bindPopup('Rondo Obornickie<br>ul. Lechicka 2<br>61-695 Poznań');

    //         L.marker([52.384367044580706, 16.876746324282582], { icon: customIcon }).addTo(map.value).bindPopup('Poznań - Górczyn<br>ul. Kordeckiego 38 B<br>60-144 Poznań');

    //         L.marker([52.320903166592174, 17.561009826123637], { icon: customIcon }).addTo(map.value).bindPopup('Września<br>ul. Wrocławska 1A<br>(stacja benzynowa SHELL)<br>62-300 Września');

    //     });

    //     onUnmounted(() => {
    //         if (map.value) {
    //             map.value.remove();
    //         }
    //     });

    //     return {
    //         map
    //     };
    // },

};
</script>

<style scoped>
@import "../styles/kontakt.css";
</style>