<template>
    <div class="home-page">

        <!-- <div v-if="isPopupVisible" class="popup-container" @click="togglePopup(false)">
            <button class="popup-close-btn">x</button>
            <img :src="popupImageSrc" alt="Popup Image" class="popup-image">
        </div> -->

        <!-- <div class="lp">
            <div class="car-container">
                <img src="../assets/photos/car.png" alt="car" class="car" />
            </div>
            <div class="green-gradient">
                <h1>SERWIS MOBILNY</h1>
                <h2>Jesteśmy gotowi pomóc,<br />gdziekolwiek jesteś!</h2>
                <div class="wrapper">
                    <div class="container">
                        <a href="tel:61-810-76-17">
                            <img src="../assets/icons/green-circles/phone-call.svg" alt="" class="phone-call" />
                        </a>
                        <a href="mailto: biuro@jurpol.pl">
                            <img src="../assets/icons/green-circles/email.svg" alt="" class="email" />
                        </a>
                    </div>
                    <div class="container green-car-container">
                        <img src="../assets/icons/green-car-field.svg" alt="" class="car-green" />
                    </div>
                </div>
            </div>
        </div> -->

        <div class="banner-swiper">
            <a v-for="(image, index) in bannerImages" :key="index" :href="image.link" target="_blank"
                class="banner-slide" :class="{ 'active': index === currentBannerIndex }">
                <img :src="image.src" :ref="'bannerImg' + index" alt="Banner Image">
                <div v-if="index === 0" class="banner-buttons">
                    <a href="tel:61 810 76 17">
                        <img src="../assets/swiper/button_zadzwon.png">
                    </a>
                    <a href="mailto: biuro@jurpol.pl">
                        <img src="../assets/swiper/button_napisz.png">
                    </a>
                </div>
            </a>
        </div>

        <div class="flex-parent">

            <!-- <div class="logo-big-container">
                <img src="../assets/logo/logo.png" alt="Logo" class="logo-big" />
            </div> -->

            <div class="text-container">
                <h3>JURPOL Szyby samochodowe</h3>
                <p>
                    jest&nbsp;<b>liderem w branży motoryzacyjnej</b>&nbsp;zajmującym się
                    serwisem szyb samochodowych, montaż szyb, wymiana szyb, przyciemnianie
                    szyb, sprzedaż szyb, naprawa szyb. Jurpol montuje szyby w autach
                    osobowych, ale również w pojazdach ciężarowych, szynowych,
                    przemysłowych, a także zabytkowych. Jako lider w branży dystrybucji i
                    wymiany szyb samochodowych zajmuje się dodatkowo wygładzaniem rys na
                    szybach samochodowych jak również naprawą pęknięć. Poprzez szeroki
                    wachlarz usług trafia do obszernego zespołu klientów.
                </p>
            </div>
        </div>
        <div class="icons-wrapper flex-parent">
            <div class="icon-container">
                <img src="../assets/icons/yellow.png" alt="" />
                <h3>Sieć salonów<br />obsługi</h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/green.png" alt="" />
                <h3>Profesjonalny<br />montaż</h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/grey.png" alt="" />
                <h3>Fachowe<br />doradztwo</h3>
            </div>
            <div class="icon-container">
                <img src="../assets/icons/red.png" alt="" />
                <h3>Gwarancja<br />jakości</h3>
            </div>
        </div>
        <div class="offer-wrapper">
            <h1>NASZA OFERTA</h1>
            <div class="photo-wrapper">
                <div class="photo-container" @click="scrollToSzyby">
                    <div class="image-overlay-container">
                        <img src="../assets/photos/offer1.png" alt="" class="offer-image" />
                        <img src="../assets/icons/glass.svg" alt="Zoom" class="overlay-icon" />
                    </div>
                    <h3>SZYBY<br />SAMOCHODOWE</h3>
                </div>
                <div class="photo-container" @click="scrollToSerwis">
                    <div class="image-overlay-container">
                        <img src="../assets/photos/offer2.png" alt="" class="offer-image" />
                        <img src="../assets/icons/glass.svg" alt="Zoom" class="overlay-icon" />
                    </div>
                    <h3>SERWIS</h3>
                </div>
                <div class="photo-container" @click="scrollToPozostale">
                    <div class="image-overlay-container">
                        <img src="../assets/photos/offer3.png" alt="" class="offer-image" />
                        <img src="../assets/icons/glass.svg" alt="Zoom" class="overlay-icon" />
                    </div>
                    <h3>POZOSTAŁE USŁUGI</h3>
                </div>
            </div>
        </div>
        <div class="green-section" id="szyby-samochodowe-section">
            <h1 class="green top">SZYBY SAMOCHODOWE</h1>
            <p class="description">
                Firma Jurpol to od ponad 30-stu lat zaufany partner w dziedzinie sprzedaży i serwisu szyb samochodowych
                w zakresie montażu, wymiany, naprawy oraz przyciemniania szyb. Jesteśmy dostawcą szyb w zakresie zarówno
                produktów markowych, OEM – szyb logowanych znakiem producenta auta, jak również dostawcą szyb w
                zamienniku.
            </p>
            <div class="two-columns">
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/osobowe.svg" alt="" />
                        <h3>OSOBOWE</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/premium.svg" alt="" />
                        <h3>PREMIUM</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/ciezarowe.svg" alt="" />
                        <h3>CIĘŻAROWE</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/old.svg" alt="" />
                        <h3>YOUNG & OLDTIMERY</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/specjalistyczne.svg" alt="" />
                        <h3>SPECJALISTYCZNE</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/rolnicze.svg" alt="" />
                        <h3>ROLNICZE</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/budowlane.svg" alt="" />
                        <h3>MASZYNY BUDOWLANE</h3>
                    </div>
                </a>
                <a href="/oferta-szyby" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/autobusy.svg" alt="" />
                        <h3>AUTOBUSY</h3>
                    </div>
                </a>
            </div>
        </div>
        <div class="green-section" style="background-color: #e0e0e0" id="serwis-section">
            <h1 class="green">SERWIS</h1>
            <p class="description">
                Serwis świadczony przez firmę Jurpol obejmuje również serwis mobilny, serwis camperów, polerowanie szyb
                oraz
                naprawę szyb samochodowych tj. odprysków i uszkodzeń szyb w różnorodnych pojazdach.
            </p>
            <div class="two-columns">
                <a href="/serwis-mobilny" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/mobilny.svg" alt="" />
                        <h3>SERWIS MOBILNY</h3>
                    </div>
                </a>
                <a href="/naprawa-szyb" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/naprawa.svg" alt="" />
                        <h3>NAPRAWA</h3>
                    </div>
                </a>
                <a href="/serwis-camper" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/camper.svg" alt="" />
                        <h3>CAMPER</h3>
                    </div>
                </a>
                <a href="https://rg-service.pl/" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/koleje.svg" alt="" />
                        <h3>KOLEJE</h3>
                    </div>
                </a>
            </div>
        </div>
        <div class="green-section" id="pozostale-section">
            <h1 class="green">POZOSTAŁE USŁUGI</h1>
            <p class="description">
                Skorzystaj z naszych dodatkowych usług obejmujących przyciemnianie szyb, kalibrację, zakup akcesoriów do
                szyb m.in. wycieraczki do szyb, zakup ekoplakietki czy wyposażenie Twojego garażu.
            </p>
            <div class="two-columns">
                <a href="/przyciemnianie-szyb" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/przyciemniane.svg" alt="" />
                        <h3>PRZYCIEMNIANE</h3>
                    </div>
                </a>
                <a href="/kalibracja-szyb" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/kalibracja.svg" alt="" />
                        <h3>KALIBRACJA</h3>
                    </div>
                </a>
                <a href="/akcesoria" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/akcesoria.svg" alt="" />
                        <h3>AKCESORIA</h3>
                    </div>
                </a>
                <a href="/ekoplakietka" class="green-wrapper link">
                    <div class="green-wrapper">
                        <img src="../assets/icons/green-circles/ekoplakietka.svg" alt="" />
                        <h3>EKOPLAKIETKA</h3>
                    </div>
                </a>
            </div>
        </div>
        <div class="opinion">
            <h1 class="green" style="margin-bottom: 0;">REFERENCJE KLIENTÓW</h1>
            <div class="swiper-container">
                <div class="swiper" :style="{ 'transform': `translateX(-${currentIndex * 100}%)` }">
                    <div class="slide local-container testimonial" v-for="testimonial in testimonials"
                        :key="testimonial.name">
                        <p>{{ testimonial.text }}</p>
                        <h2>{{ testimonial.name }}</h2>
                        <img src="../assets/icons/stars.svg" style="height: 1rem;" />
                    </div>
                </div>
                <div class="dots">
                    <span class="dot" v-for="(testimonial, index) in testimonials" :key="testimonial.name"
                        :class="{ active: index === currentIndex }" @click="goToSlide(index)"></span>
                </div>
            </div>
        </div>
        <div class="more-section">
            <div class="txt">
                <img src="../assets/logo/rgs.png" alt="" />
                <br>
                <h2 style="text-align: center;">Pewny serwis. Bezpieczne koleje.</h2>
                <a href="https://rg-service.pl/" class="button" rel="noreferrer" target="_blank">Dowiedz się więcej</a>
            </div>
            <div class="metro-container">
                <img src="../assets/photos/metro.png" alt="" />
            </div>
        </div>
        <div class="insurance">
            <h1>UBEZPIECZENIA</h1>
            <div class="insurance-wrapper">
                <a href="https://www.pzu.pl/dla-ciebie-i-rodziny/majatek-podroze-oc/pojazdy/pzu-auto-szyba"
                    rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/pzu.svg" alt="PZU" />
                </a>
                <a href="https://www.link4.pl/ubezpieczenie-szyb" rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/link.png" alt="LINK4" />
                </a>
                <a href="https://www.warta.pl/ubezpieczenie-szyb-samochodowych/" rel="noopener noreferrer"
                    target="_blank">
                    <img src="../assets/logo/warta.png" alt="WARTA" />
                </a>
            </div>
            <div class="insurance-wrapper">
                <a href="https://www.ergohestia.pl/centrum-wiedzy/ubezpieczenie-szyb-samochodu-czy-warto-wykupic/"
                    rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/ergo.png" alt="ergoHestia" />
                </a>
                <a href="https://www.allianz.pl/pl_PL/dla-ciebie/samochod/ubezpieczenie-szyb.html"
                    rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/allianz.svg" alt="ALLIANZ" />
                </a>
                <a href="https://tuz.pl/produkty/autoszyby/?utm_term=&utm_campaign=&utm_source=adwords&utm_medium=ppc&hsa_acc=7300799895&hsa_cam=20753076127&hsa_grp=&hsa_ad=&hsa_src=x&hsa_tgt=&hsa_kw=&hsa_mt=&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=Cj0KCQiAqsitBhDlARIsAGMR1RjPswWPvZrZ7n0Hz3s7FlYjjLS1lXAHHEN3hLOrXUyzkQPzhZ1zL58aApm8EALw_wcB"
                    rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/tuz.png" alt="TUZ" />
                </a>
            </div>
            <div class="insurance-wrapper">
                <a href="https://www.generali.pl/dla-ciebie/autoszyby" rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/generali.png" alt="GENERALI" />
                </a>
                <a href="https://wiener.pl/ubezpieczenia-auto" rel="noopener noreferrer" target="_blank">
                    <img src="../assets/logo/wiener.jpg" alt="WIENER" />
                </a>
                <a href="https://www.uniqa.pl/ubezpieczenie-oc/ubezpieczenie-szyb/" rel="noopener noreferrer"
                    target="_blank">
                    <img src="../assets/logo/uniqa.png" alt="UNIQA" />
                </a>
            </div>
        </div>
    </div>
</template>

<script>

export default {

    name: 'HomePage',
    data() {
        return {
            currentIndex: 0,
            testimonials: [
                {
                    name: 'Firma Ronada',
                    text: 'W imieniu całego zarządu firmy Ronada. Chcieliśmy serdecznie podziękować firmie JURPOL szyby samochodowe za szybką pomoc w wymianie przedniej szyby w aucie, które znajdowało się na drugim końcu Niemiec. Z firmą JURPOL współpracujemy już od lat. Tym razem potrzebowaliśmy ich bardziej niż kiedykolwiek. I wiecie co? Jesteśmy zaskoczeni. Firma JURPOL stanęła na wysokości zadania. Nie zostawiła nas w potrzebie. Jeśli macie szybę do wymiany lub potrzebujecie usługi, z nimi związaną to tylko JURPOL!',
                },
                {
                    name: 'Adrian', text: 'Bardzo miły personel jak i cała ekipa, która wykonała świetną pracę w szybkim terminie oraz czasie przy przyciemnianiu szyb, zdecydowanie polecam firmę, jestem zadowolony, cenowo uważam też atrakcyjnie. Serdecznie pozdrawiam :)'
                },
                {
                    name: 'Sandra', text: 'Polecam. Przemiła obsługa na najwyższym poziomie, szyba idealnie wstawiona, wszystko wytłumaczone i odkurzony środek. Dziękuje Panu, który został po godzinach.'
                },
                {
                    name: 'Piotr', text: 'Już korzystałem wcześniej z usługi wymiany szyby w innym samochodzie. Tym razem szybka naprawa odprysku na szybie w 25 minut, uszkodzenie szyby zniknęło! Polecam'
                },
                {
                    name: 'Ilona', text: 'Jestem bardzo zadowolona z usługi wymiany przedniej szyby która odbyła się w sobotę. Sympatyczne Panie w biurze i na infolinii. Panowie, którzy wymienili mi szybę zrobili to profesjonalnie, dodatkowo odkurzyli mi auto w środku bardzo miło z ich strony. Odebrałam samochód bardziej czysty jak przed :) dziękuję'
                },
            ],
            touchStartX: 0,
            currentBannerIndex: 0,
            bannerImages: [
                {
                    src: require('@/assets/swiper/baner1.jpg'),
                    altSrc: require('@/assets/swiper/baner1-mobile.jpg'),
                    link: '/'
                },
                {
                    src: require('@/assets/swiper/baner2.jpg'),
                    altSrc: require('@/assets/swiper/baner2-mobile.jpg'),
                    link: '/oferta-szyby'
                },
            ],
            // isPopupVisible: false,
            // popupImageSrc: '',

            // popupImageSrc: require('@/assets/popupImageLarge.jpg'), // Default image for larger screens
            //   popupImageAlt: 'Życzenia Wielkanocne od Jurpol', // Default alt text
        };
    },

    methods: {
        goToSlide(index) {
            this.currentIndex = index;
        },
        handleTouchStart(event) {
            this.touchStartX = event.touches[0].clientX;
        },
        handleTouchEnd(event) {
            const touchEndX = event.changedTouches[0].clientX;
            const threshold = 50;
            const distance = touchEndX - this.touchStartX;
            if (Math.abs(distance) >= threshold) {
                if (distance > 0) {
                    this.prevSlide();
                } else {
                    this.nextSlide();
                }
            }
        },
        nextSlide() {
            this.currentIndex = (this.currentIndex + 1) % this.testimonials.length;
        },
        prevSlide() {
            this.currentIndex = (this.currentIndex - 1 + this.testimonials.length) % this.testimonials.length;
        },
        scrollToSzyby() {
            const targetSection = document.getElementById('szyby-samochodowe-section');
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        scrollToSerwis() {
            const targetSection = document.getElementById('serwis-section');
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        scrollToPozostale() {
            const targetSection = document.getElementById('pozostale-section');
            if (targetSection) {
                targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        },
        nextBanner() {
            this.currentBannerIndex = (this.currentBannerIndex + 1) % this.bannerImages.length;
        },
        updateImageSources() {
            const isMobile = window.innerWidth <= 900;
            this.bannerImages.forEach((banner, index) => {
                const imgElement = this.$refs[`bannerImg${index}`][0];
                if (imgElement) {
                    imgElement.src = isMobile ? banner.altSrc : banner.src;
                }
            });
        },
        // togglePopup(show) {
        //     this.isPopupVisible = show;
        // },
        // showPopupWithImage(imageSrc) {
        //     this.popupImageSrc = imageSrc;
        //     this.togglePopup(true);
        // },
    },
    mounted() {
        const swiper = this.$el.querySelector('.swiper');
        swiper.addEventListener('touchstart', this.handleTouchStart);
        swiper.addEventListener('touchend', this.handleTouchEnd);

        this.bannerSwitchTimer = setInterval(this.nextBanner, 3000); // Switch banners every 3 seconds

        this.updateImageSources();
        window.addEventListener('resize', this.updateImageSources);

        // this.showPopupWithImage(require('@/assets/popupImageLarge.jpg'));

        //     if (window.innerWidth <= 600) {
        //   this.popupImageSrc = require('@/assets/popupImageSmall.jpg'); 
        //   this.popupImageAlt = 'Życzenia Wielkanocne od Jurpol'; 
        //     }
    },
    beforeUnmount() {
        const swiper = this.$el.querySelector('.swiper');
        swiper.removeEventListener('touchstart', this.handleTouchStart);
        swiper.removeEventListener('touchend', this.handleTouchEnd);

        clearInterval(this.bannerSwitchTimer);

        window.removeEventListener('resize', this.updateImageSources);
    }
};

</script>

<style scoped>
@import "../styles/style.css";
</style>
