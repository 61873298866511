<template>
    <div class="green-side">
        <div class="header-container flex-parent">
            <div class="txt-side">
                <h1>INSIDE GARAGE</h1>
                <p>
                    Jako partner Inside Garage w regionie Wielkopolskim, jesteśmy
                    specjalistami w dziedzinie sprzedaży, montażu i dostarczania rozwiązań
                    oraz produktów, które pozwolą uporządkować przestrzeń w Twoim garażu,
                    warsztacie, pomieszczeniu gospodarczym lub spiżarni. Nasi eksperci
                    pomogą Ci w efektywnym wyposażeniu tego miejsca, zapewniając porządek
                    i funkcjonalność.
                </p>
            </div>
            <div class="top-side">
                <img src="../assets/icons/garage.png" />
            </div>
        </div>
        <section>
            <h2 class="smaller">Od koncepcji po realizację garażu marzeń</h2>
            <div class="flex-parent side-parent">
                <div class="side-txt grey">
                    <p>
                        Jeżeli zastanawiasz się, jak urządzić swój garaż, nie wahaj się
                        zwrócić do nas o pomoc. Przygotujemy projekt Twojego garażu i
                        przeprowadzimy Cię krok po kroku przez cały proces, począwszy od
                        pomysłu po pełną realizację.
                    </p>
                </div>
                <div class="side-photo">
                    <img src="../assets/photos/garage1.png" />
                </div>
            </div>
            <div class="flex-parent side-parent">
                <div class="side-txt">
                    <h2 class="smaller">Bogaty wybór produktów dla garażu i warsztatu</h2>
                    <p>
                        W naszej ofercie znajdziesz pełen zakres niezbędnych elementów dla
                        Twojego garażu: od trwałej posadzki garażowej po stoły warsztatowe,
                        stojaki na opony, haki na rowery oraz narzędzia ogrodowe, aż po
                        meble garażowe renomowanej firmy Wolfcraft.
                    </p>
                </div>
                <div class="side-photo">
                    <img src="../assets/photos/garage2.png" />
                </div>
            </div>
            <h2 class="smaller">Posadzki garażowe Fortelock - szybka i trwała alternatywa</h2>
            <div class="flex-parent side-parent">
                <div class="side-txt">
                    <p>
                        System Fortelock składa się z wzajemnie połączonych płyt z 100% PVC,
                        doskonale nadających się zarówno do nowych, jak i istniejących
                        podłoży o różnej jakości. Posadzki Fortelock charakteryzują się
                        wyjątkową odpornością na zużycie i długotrwałą stabilnością. Systemy
                        Fortelock doskonale sprawdzają się jako podłogi do biura, na eventy
                        firmowe, na wystawy, jako podłogi do magazynu orz warsztatu.
                        Ponadto, zapewniamy również możliwość wynajmu podłóg Fortelock.
                    </p>
                </div>
                <div class="side-photo">
                    <img src="../assets/photos/garage3.png" />
                </div>
            </div>
            <div class="txt-section" style="text-align: center">
                <p>
                    Z Inside Garage Twój garaż lub warsztat stanie się idealnie
                    zorganizowaną przestrzenią, która spełni Twoje oczekiwania i potrzeby.
                </p>
  
                <h2 class="more">Po więcej szczegółów zerknij na:</h2>
        
                <a href="https://insidegarage.pl/" class="button">www.insidegarage.pl</a>
            </div>
        </section>
    </div>
</template>
  
<script>
export default {
    name: 'InsideGarage',
};
</script>

<style scoped>
@import "../styles/green-side.css";
</style>
  