<template>
    <div class="kariera-page">
        <section>
            <div class="flex-parent group-container">
                <h1>Dołącz do naszego<br /><b class="green">zespołu!</b></h1>
                <img src="../assets/photos/group.png" />
            </div>
            <div class="icons-wrapper flex-parent desktop">
                <div class="icon-container">
                    <img src="../assets/icons/yellow.png" alt="" />
                    <h3>Sieć salonów<br />obsługi</h3>
                </div>
                <div class="icon-container">
                    <img src="../assets/icons/green.png" alt="" />
                    <h3>Profesjonalny<br />montaż</h3>
                </div>
                <div class="icon-container">
                    <img src="../assets/icons/grey.png" alt="" />
                    <h3>Fachowe<br />doradztwo</h3>
                </div>
                <div class="icon-container">
                    <img src="../assets/icons/red.png" alt="" />
                    <h3>Gwarancja<br />jakości</h3>
                </div>
            </div>
            <div class="icons-wrapper flex-parent mobile">
                <div class="row">
                    <div class="icon-container">
                        <img src="../assets/icons/yellow.png" alt="" />
                        <h3>Sieć salonów<br />obsługi</h3>
                    </div>
                    <div class="icon-container">
                        <img src="../assets/icons/green.png" alt="" />
                        <h3>Profesjonalny<br />montaż</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="icon-container">
                        <img src="../assets/icons/grey.png" alt="" />
                        <h3>Fachowe<br />doradztwo</h3>
                    </div>
                    <div class="icon-container">
                        <img src="../assets/icons/red.png" alt="" />
                        <h3>Gwarancja<br />jakości</h3>
                    </div>
                </div>
            </div>
        </section>
        <div class="job-offer-wrapper">
            <div class="flex-parent">
                <div class="info wider">
                    <h2 class="green">
                        Stanowisko
                    </h2>
                </div>
                <div class="info">
                    <h2 class="green">
                        Dział
                    </h2>
                </div>
                <div class="info">
                    <h2 class="green">
                        Data<br>publikacji
                    </h2>
                </div>
            </div>
        </div>
        <section class="job-offer-container">
            <div class="job-offer flex-parent" v-for="offer in jobOffers" :key="offer.id" @click="selectJob(offer)">
                <div class="info wider">
                    <h2 class="green mobile">
                        Stanowisko
                    </h2>
                    <p>{{ offer.jobTitle }}</p>
                </div>
                <div class="info">
                    <h2 class="green mobile">
                        Dział
                    </h2>
                    <p>{{ offer.department }}</p>
                </div>
                <div class="info">
                    <h2 class="green mobile">
                        Data publikacji
                    </h2>
                    <p>{{ offer.publicationDate }}</p>
                </div>
            </div>
        </section>
        <!-- <JobApplyModal :visible="showModal" :jobTitle="selectedJob.jobTitle" :cta="selectedJob.cta"
            :responsibilities="selectedJob.responsibilities" :requirements="selectedJob.requirements"
            :offer="selectedJob.offer" @close="showModal = false" /> -->

        <JobApplyModal :visible="showModal" :jobTitle="selectedJob.jobTitle" :department="selectedJob.department"
            :cta="selectedJob.cta" :responsibilities="selectedJob.responsibilities"
            :requirements="selectedJob.requirements" :offer="selectedJob.offer" @close="showModal = false" />


    </div>
</template>

<script>
import JobApplyModal from '../components/JobApplyModal.vue';

export default {
    name: 'KarieraPage',
    components: {
        JobApplyModal,
    },
    data() {
        return {
            showModal: false,
            selectedJob: '', // to hold the selected job's details
            jobOffers: [
                {
                    id: 1,
                    jobTitle: 'Monter szyb samochodowych',
                    department: 'Poznań',
                    publicationDate: '07.02.2024',
                    cta: 'Jeśli jesteś dokładny i sprawny manualnie, masz doświadczenie w branży motoryzacyjnej lub chcesz się rozwijać i zdobyć zawód to dołącz do naszego zespołu!',
                    responsibilities: [
                        'wymiana, montaż oraz naprawa szyb samochodowych w różnorodnych pojazdach,',
                        'realizacja zleceń zgodnie z procedurami firmowymi',
                    ],
                    requirements: [
                        'doświadczenie techniczne na stanowisku montera szyb lub w branży motoryzacyjnej (jeśli nie masz doświadczenia, ale jesteś zmotywowany do działania i chcesz zdobyć zawód to zaaplikuj)',
                        'sprawna obsługa komputera oraz pakietu Ms Office,',
                        'czynne prawo jazdy kategorii B,',
                        'sumienność oraz dbałość o szczegóły,',
                        'dokładność,',
                        'umiejętność rozwiązywania problemów - orientacja na poszukiwanie rozwiązań,',
                        'umiejętność współpracy w zespole oraz organizacji pracy własnej.'
                    ],
                    offer: [
                        'pracę w renomowanej firmie istniejącej od ponad 30 lat na rynku,',
                        'pełne wsparcie i przeszkolenie z procedur i technologii montażu,',
                        'stabilne zatrudnienie w oparciu o umowę o pracę,',
                        'wynagrodzenie - podstawa plus premia,',
                        'praca w pełnym wymiarze godzinowym,',
                        'możliwość rozwoju,',
                        'wyposażenie,',
                        'dobre zaplecze socjalne,',
                        'przyjazną atmosferę.'
                    ]
                },
                {
                    id: 2,
                    jobTitle: 'Monter szyb samochodowych',
                    department: 'Komorniki',
                    publicationDate: '07.02.2024',
                    cta: 'Jeśli jesteś dokładny i sprawny manualnie, masz doświadczenie w branży motoryzacyjnej lub chcesz się rozwijać i zdobyć zawód to dołącz do naszego zespołu!',
                    responsibilities: [
                        'wymiana, montaż oraz naprawa szyb samochodowych w różnorodnych pojazdach,',
                        'realizacja zleceń zgodnie z procedurami firmowymi',
                    ],
                    requirements: [
                        'doświadczenie techniczne na stanowisku montera szyb lub w branży motoryzacyjnej (jeśli nie masz doświadczenia, ale jesteś zmotywowany do działania i chcesz zdobyć zawód to zaaplikuj)',
                        'sprawna obsługa komputera oraz pakietu Ms Office,',
                        'czynne prawo jazdy kategorii B,',
                        'sumienność oraz dbałość o szczegóły,',
                        'dokładność,',
                        'umiejętność rozwiązywania problemów - orientacja na poszukiwanie rozwiązań,',
                        'umiejętność współpracy w zespole oraz organizacji pracy własnej.'
                    ],
                    offer: [
                        'pracę w renomowanej firmie istniejącej od ponad 30 lat na rynku,',
                        'pełne wsparcie i przeszkolenie z procedur i technologii montażu,',
                        'stabilne zatrudnienie w oparciu o umowę o pracę,',
                        'wynagrodzenie - podstawa plus premia,',
                        'praca w pełnym wymiarze godzinowym,',
                        'możliwość rozwoju,',
                        'wyposażenie,',
                        'dobre zaplecze socjalne,',
                        'przyjazną atmosferę.'
                    ]
                },
                {
                    id: 3,
                    jobTitle: 'Doświadczony/na specjalista/tka ds. obsługi Klienta',
                    department: 'Poznań',
                    publicationDate: '07.02.2024',
                    cta: 'Jeśli jesteś komunikatywny i lubisz pracę z ludźmi, umiesz doradzić klientowi i budować trwałe relacje z partnerami to właśnie Ciebie szukamy! Potrzebujemy osoby, która sprawnie poprowadzi, połączy i rozwiąże wszelkie sprawy pojawiające się w życiu codziennym naszej centrali. Zależy nam na osobie samodzielnej, która patrzy szerzej na procesy, umie je usprawnić i potrafi nimi zarządzać realizując przy tym cele biznesowe firmy. Zależy nam na osobie doświadczonej, która w krótkim czasie stanie się "prawą ręką Prezesa" i kierownikiem centrali.',
                    responsibilities: [
                        'aktywna sprzedaż i poszukiwanie nowych klientów (udokumentowane doświadczenie sprzedażowe),',
                        'telefoniczna oraz mailowa obsługa klienta,',
                        'obsługa zapytań -> udzielenie informacji zwrotnej, doradztwo w zakresie doboru rozwiązania, przygotowanie oferty, prowadzenie dokumentacji oraz realizacja zlecenia,',
                        'obsługa klienta na wysokim poziomie,',
                        'współpraca z ubezpieczalniami,',
                        'prowadzenie pełnej dokumentacji związanej z obsługa zlecenia i zgodnej ze standardami firmy,',
                        'obsługa klienta zgodnie z procedurami firmowymi,',
                        'budowanie długotrwałych relacji z partnerami,',
                        'zarządzanie procesami powiązanymi z obsługą klienta m.in. zamówieniami, pracą zespołu, planowaniem zadań.'
                    ],
                    requirements: [
                        'min. 4 lata doświadczenia w zakresie obsługi klienta,',
                        'sprawna obsługa komputera oraz pakietu Ms Office,',
                        'łatwość pracy na kilku programach informatycznych jednocześnie,',
                        'umiejętności sprzedażowe i budowanie długotrwałych relacji z klientem,',
                        'sumienność oraz dbałość o szczegóły,',
                        'komunikatywność,',
                        'dokładność,',
                        'własnej inicjatywy w poszukiwaniu rozwiązań,',
                        'nastawienia na realizację celów,',
                        'umiejętność rozwiązywania problemów - orientacja na poszukiwanie rozwiązań,',
                        'umiejętność współpracy w zespole oraz organizacji pracy własnej,',
                        'znajomość branży motoryzacyjnej i ubezpieczeniowej będzie dodatkowym atutem.'
                    ],
                    offer: [
                        'pracę w renomowanej firmie istniejącej od ponad 30 lat na rynku,',
                        'pełne wsparcie i przeszkolenie w trakcie wdrożenia,',
                        'stabilne zatrudnienie w oparciu o umowę o pracę,',
                        'premię - wynagrodzenie powiązane z wynikami pracy',
                        'stałe godziny pracy,',
                        'przyjazną atmosferę,',
                        'pyszną kawę i częste słodkie przekąski do kawy.'
                    ]
                },
                // Add more job offers here
            ],
        };
    },
    methods: {
        selectJob(job) {
            this.selectedJob = job;
            this.showModal = true;
        },
    },
};
</script>

<style scoped>
@import "../styles/kariera.css";
</style>