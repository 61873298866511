<template>
  <footer>
    <div class="footer-column">
      <div class="footer-logo">
        <img src="../assets/logo/logo.png" />
      </div>
    </div>
    <div class="footer-column">
      <h3>Centrala Komorniki</h3>
      <p>
        ul. Wirowska 30,<br /><br />
        62-052 Komorniki<br /><br />
      </p>
      <h3>Dni otwarcia:</h3>
      <div class="contact-container">
        <img src="../assets/icons/clock.svg" />
        pon-pt: 8:00 - 18:00<br /><br />
        sob: 8:00 - 14:00
      </div>
    </div>
    <!-- <div class="footer-column">
      <h3>Dni otwarcia:</h3>
      <div class="contact-container">
        <img src="../assets/icons/clock.svg" />
        pon-pt: 8:00 - 18:00<br /><br />
        sob: 8:00 - 14:00
      </div>
    </div> -->

    <div class="footer-column">
      <h3>Do pobrania:</h3>
      <a href="/assets/downloads/Pliki-cookies.pdf" download="Pliki-cookies">
        <p>Pliki cookies</p>
      </a>

      <a href="/assets/downloads/POLITYKA-OCHRONY-DANYCH-OSOBOWYCH-I-COOKIE-JURPOL.pdf" download="Polityka-Prywatnosci">
        <p>Polityka Prywatności</p>
      </a>
    </div>

    <div class="footer-column">
      <div class="contact-wrapper">
        <h3 style="margin-bottom: 0">Kontakt:</h3>
        <div class="contact-container">
          <img src="../assets/icons/phone.svg" />
          <a href="tel:618107617">61 810 76 17</a>
        </div>
        <div class="contact-container">
          <img src="../assets/icons/email.svg" /><b>email:&nbsp;</b><a
            href="mailto: biuro@jurpol.pl">biuro@jurpol.pl</a>
        </div>
        <div class="contact-container">
          <img src="../assets/icons/facebook.svg" />
          <a href="https://www.facebook.com/jurpol.szybysamochodowe/">
            @jurpol.szybysamochodowe</a>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
};
</script>

<style scoped>
@import "../styles/footer.css";
</style>
