<template>
    <div class="green-side">
        <div class="header-container flex-parent">
            <div class="txt-side">
                <h1>AKCESORIA</h1>
                <p>
                    Akcesoria Samochodowe Jurpol - Zapewniające Bezpieczeństwo i Komfort Podróżowania.<br /><br />Firma
                    Jurpol dba nie tylko o jakość szyb
                    samochodowych, ale także oferuje szeroki wybór akcesoriów, które
                    znacząco poprawiają wygodę i bezpieczeństwo Twoich podróży.
                </p>
            </div>
            <div class="top-side">
                <img src="../assets/icons/akcesoria.png" />
            </div>
        </div>
        <section>
            <div class="ac-wrapper">
                <div class="ac-parent">
                    <img src="../assets/photos/ac1.png" />
                    <h2>Wycieraczki Samochodowe - Klucz do Doskonałej Widoczności</h2>
                    <p>
                        W naszym asortymencie znajdziesz wysokiej jakości wycieraczki
                        dostosowane do każdego modelu samochodu. Pamiętaj, że wycieraczki
                        odgrywają kluczową rolę w zapewnieniu prawidłowej widoczności na
                        drodze. Zalecamy regularną wymianę wycieraczek przed nadejściem
                        jesienno-zimowego okresu, aby zagwarantować sobie doskonałą
                        widoczność oraz bezpieczeństwo.
                    </p>
                </div>
                <div class="ac-parent">
                    <img src="../assets/photos/ac2.png" />
                    <h2>
                        Kleje i Akcesoria do Montażu Szyb Samochodowych - Dla
                        Profesjonalistów
                    </h2>
                    <p>
                        Oferujemy sprzedaż klejów oraz akcesoriów dedykowanych warsztatom i
                        profesjonalistom zajmującym się montażem szyb samochodowych.
                    </p>
                </div>
                <div class="ac-parent">
                    <img src="../assets/photos/ac3.png" />
                    <h2>
                        Uszczelki do Szyb Samochodowych - Ochrona Przed Czynnikami
                        Zewnętrznymi
                    </h2>
                    <p>
                        Nasza oferta obejmuje uszczelki dedykowane różnym modelom pojazdów
                        samochodowych.
                    </p>
                </div>
                <div class="ac-parent">
                    <img src="../assets/photos/ac4.png" />
                    <h2>Płyn do Spryskiwaczy - Skuteczna Ochrona Przed Brudem</h2>
                    <p>
                        Oferujemy płyny do spryskiwaczy, które są dostosowane do różnych pór
                        roku. Nasze produkty zapewniają skuteczne usuwanie zabrudzeń i
                        osadów, co jest kluczowe dla efektywnego działania spryskiwaczy.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
  
<script>

export default {
    name: 'AkcesoriaPage',
};
</script>

<style scoped>
@import "../styles/green-side.css";
</style>
  